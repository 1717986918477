import React from 'react';
import styled from 'styled-components';

const ServiceNumberedList = ({ list }) => {
    const subtitle = list.find((item) => item.introduction);
    const items = list.filter((item) => item.title);
    return (
        <Container>
          <h5>{subtitle.introduction}</h5>
          <ol>
            {items.map((item) => (
              <li>{item.title}</li>
            ))}
          </ol>
        </Container>
    )
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  h5 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  ol {
    margin-left: 1.25rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (min-width: 1024px) {
    margin-left: -1.25rem;
  }
`;

export default ServiceNumberedList;