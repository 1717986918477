import * as React from "react";
const SvgSwipeArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 12"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeWidth={2}
      d="M7.166.77 2 5.77l5.166 5M2.445 5.77h11.556"
    />
  </svg>
);
export default SvgSwipeArrow;
