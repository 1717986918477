import * as React from "react";
const SvgLaboratory2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 93 77"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m65.834 49.48-3.47-3.525M72.045 49.474a4.348 4.348 0 0 0-6.207 0 4.49 4.49 0 0 0 0 6.29L83.69 73.855a4.348 4.348 0 0 0 6.207 0 4.49 4.49 0 0 0 0-6.29l-17.852-18.09Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M28.096 47.714c-1.384-13.993-4.949-28.232 7.718-41.076M33.606 35.377l8.008 8.115M34.1 23.93l17.17 17.4M37.664 15.602l18.407 18.653"
    />
    <circle cx={35.086} cy={12.948} r={1} fill={props.color} />
    <circle cx={31.71} cy={21.159} r={1} fill={props.color} />
    <circle cx={31.034} cy={32.791} r={1} fill={props.color} />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M33.566 43.307c10.141.924 20.189.363 29.486-9.06"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M43.984 53.66c14.35 0 25.982-11.788 25.982-26.33C69.967 12.788 58.335 1 43.985 1S18.002 12.788 18.002 27.33c0 14.542 11.633 26.33 25.982 26.33Z"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.906 70.794c8.33-7.458 7.048-15.7 5.956-23.95M5.463 51.758l12.789 12.966M9.042 47.091l11.93 12.084M15.767 45.606l5.375 5.44"
    />
    <circle cx={23} cy={53} r={1} fill="#061C57" />
    <circle cx={23} cy={61} r={1} fill="#061C57" />
    <circle cx={20.231} cy={67.004} r={1} fill="#061C57" />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 51.71c6.61-6.699 13.275-6.877 20.574-6.131"
    />
  </svg>
);
export default SvgLaboratory2;
