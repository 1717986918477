import * as React from "react";
const SvgLaboratory3 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 98 83"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.803 61.911H97M27.397 81.3h43.2M41.801 69.179l-4.804 12.12M56.199 69.179l4.804 12.12M51.402 69.179H5.797C2.629 69.179 1 67.534 1 64.334V6.145C1 2.945 2.629 1.3 5.797 1.3h86.399c3.183 0 4.797 1.638 4.797 4.845v58.189c0 3.2-1.63 4.845-4.797 4.845h-31.2M10.6 61.911H5.798"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M36.75 40.68h-9.6v14.544h9.6V40.681ZM36.863 30.734H27.15v4.528h9.713v-4.528ZM70.484 16.394h-9.713v4.528h9.713v-4.528ZM53.935 33.413h-9.6v21.819h9.6V33.413ZM54.047 21.677h-9.713v6.793h9.713v-6.793ZM54.047 8.847h-9.713v6.792h9.713V8.847ZM36.863 14.13H27.15v11.32h9.713V14.13Z"
    />
    <ellipse cx={70.596} cy={45.534} fill={props.color} rx={1.494} ry={1.509} />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M70.596 50.379v4.853h-9.593V26.138h9.593V40.68"
    />
  </svg>
);
export default SvgLaboratory3;
