import React, { useRef } from "react";
import styled from "styled-components";
import { motion, useScroll, useTransform, cubicBezier } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BannerGridLeft, BannerGridRight } from "../../assets/svgs";

const ImageBanner = () => {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "assets/images/banner" } }
          sort: { name: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 90
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `
  );

  const containerRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["end end", "start start"]
  });

  const useParallax = (value, distance) => {
    return useTransform(value, [0, 1], [-distance, distance], { ease: cubicBezier(0.17, 0.67, 0.83, 0.67) });
  }

  const leftY = useParallax(scrollYProgress, 30);
  const middleY = useParallax(scrollYProgress, -30);
  const rightY = useParallax(scrollYProgress, 20);

  return (
    <Container ref={containerRef}>
      <Left style={{ y: leftY }} >
        <GatsbyImage
          image={getImage(images.edges[0].node.childImageSharp)}
          alt={images.edges[0].node.name}
        />
        <GatsbyImage
          image={getImage(images.edges[1].node.childImageSharp)}
          alt={images.edges[1].node.name}
        />
      </Left>
      <Middle style={{ y: middleY }}>
        <GatsbyImage
          image={getImage(images.edges[2].node.childImageSharp)}
          alt={images.edges[2].node.name}
        />
      </Middle>
      <Right style={{ y: rightY }}>
        <GatsbyImage
          image={getImage(images.edges[3].node.childImageSharp)}
          alt={images.edges[3].node.name}
        />
        <GatsbyImage
          image={getImage(images.edges[4].node.childImageSharp)}
          alt={images.edges[4].node.name}
        />
      </Right>
      <SVGContainerLeft>
        <BannerGridLeft />
      </SVGContainerLeft>
      <SVGContainerRight>
        <BannerGridRight />
      </SVGContainerRight>
    </Container>
  );
};

const Container = styled(motion.section)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  div {
    grid-row-start: 0;
    grid-row-end: 1;
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 9rem;
  }
`;

const Left = styled(motion.div)`
  grid-column: 1/5;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  max-height: 412px;
  position: relative;
  margin-left: -1rem;
  padding-right: 2rem;
  z-index: 3;
  div {
    border-radius: 0.25rem;
    grid-row-start: 0;
    grid-row-end: 1;
    height: auto;
    position: relative;
    width: 100%;
    img {
      box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
      height: auto;
      width: 100%;
    }
    &:nth-of-type(1) {
      grid-column: 1/4;
      z-index: 3;
    }
    &:nth-of-type(2) {
      grid-column: 3/7;
      top: 1rem;
      z-index: 1;
    }
  }
  @media screen and (min-width: 1024px) {
    margin: 0;
    padding: 0;
    grid-column: 1/6;
    div {
      &:nth-of-type(1) {
        top: 6rem;
        z-index: 3;
      }
      &:nth-of-type(2) {
        top: 9rem;
        grid-column: 3/7;
      }
    }
  }
`;

const Middle = styled(motion.div)`
  grid-column: 4/7;
  position: relative;
  z-index: 0;
  div {
    border-radius: 0.25rem;
    box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
    width: 110%;
    z-index: 1;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 6/11;
    padding: 0 0.75rem;
    div {
      width: 100%;
    }
  }
`;

const Right = styled(motion.div)`
  grid-column: 4/7;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr;
  align-self: end;
  padding: 3rem 0.75rem 0 0.25rem;
  position: relative;
  z-index: 2;
  div {
    align-self: end;
    border-radius: 0.25rem;
    grid-row-start: 2;
    grid-row-end: 3;
    height: 100%;
    position: relative;
    width: auto;
    img {
      box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
      height: 100%;
      width: auto;
    }
    &:nth-of-type(1) {
      grid-column: 1/5;
      z-index: 2;
    }
    &:nth-of-type(2) {
      grid-column: 5/7;
      margin-left: 0.75rem;
      z-index: 2;
    }
  }
  @media screen and (min-width: 1024px) {
    padding: 0;
    grid-template-rows: 1fr;
    grid-column: 9/13;
    top: 3rem;
    div {
      grid-row-start: 0;
      grid-row-end: 1;
    }
  }
`;

const SVGContainerLeft = styled.div`
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  svg {
    height: 100%;
    max-height: 62px;
    width: auto;
  }
  @media screen and (min-width: 1024px) {
    z-index: 2;
    bottom: -4rem;
    transform: translate(0, 0);
    top: unset;
    svg {
      max-height: 170px;
    }
  }
  @media screen and (min-width: 1600px) {
    bottom: -2rem;
  }
`;

const SVGContainerRight = styled.div`
  right: 0;
  position: absolute;
  justify-content: flex-end;
  display: none;
  z-index: 1;
  svg {
    height: 100%;
    max-height: 170px;
    width: auto;
    margin-left: auto;
  }
  @media screen and (min-width: 1024px) {
    display: flex;
    top: 4rem;
  }
`;

export default ImageBanner;
