import * as React from "react";
const SvgBiopharma6 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 56 44"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29.352 43H3.698C1.918 43 1 41.984 1 40.002V3.998C1 2.016 1.918 1 3.698 1h48.604C54.092 1 55 2.012 55 3.998v36.004C55 41.984 54.082 43 52.302 43h-17.55"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.61 33.444H7.765v2.895h5.847v-2.895ZM13.68 21.19H7.764v2.803h5.916V21.19Z"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M37.365 12.324h-5.916v2.802h5.916v-2.802Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M37.365 24.172h-5.916v2.802h5.916v-2.802ZM48.235 7.648H42.32v2.802h5.915V7.648ZM25.91 33.444h-5.847v2.9h5.847v-2.9Z"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M25.91 27.345h-5.847v2.9h5.847v-2.9ZM48.2 33.444h-5.847v2.9h5.846v-2.9ZM48.2 25.57h-5.847v4.676h5.846V25.57ZM48.2 17.456h-5.847v4.676h5.846v-4.676ZM25.978 15.591h-5.915v4.201h5.915v-4.2Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M25.978 7.648h-5.915v4.2h5.915v-4.2Z"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.68 10.915H7.764v3.502h5.916v-3.502Z"
    />
  </svg>
);
export default SvgBiopharma6;
