import React, { useContext } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Markup } from "interweave";
import { Button } from "../../../components";
import { LocalContext } from "../../../context";
import { useWindowSize } from "../../../hooks";

const CallToActionBanner = ({ text }) => {
  const { backgroundImage, backgroundImageMobile } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(
          relativePath: { eq: "assets/images/call-to-action-banner.png" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        backgroundImageMobile: file(
          relativePath: { eq: "assets/images/call-to-action-banner-mobile.png" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    `
  );
  const { windowWidth } = useWindowSize();
  const { setShowContactFormDialog } = useContext(LocalContext);
  return (
    <Container>
      <Background>
        {windowWidth >= 1024 ? (
          <GatsbyImage
            image={getImage(backgroundImage)}
            alt="Background"
            style={{ zIndex: 0, height: "100%" }}
          />
        ) : (
          <GatsbyImage
            image={getImage(backgroundImageMobile)}
            alt="Background"
            style={{ zIndex: 0, height: "100%" }}
          />
        )}
      </Background>
      <p>
       <Markup content={text} />
      </p>
      <Button
        type="button"
        solid="var(--genseq-purple)"
        text="Contact Us"
        id="contact-us-cta-banner"
        onClick={() => setShowContactFormDialog(true)}
      />
    </Container>
  );
};

const Container = styled.section`
  border-radius: 10px;
  grid-column: 1 / 7;
  display: flex;
  flex-direction: column;
  gap: 4.875rem;
  margin: 3rem 1.25rem;
  padding: 2.625rem 2rem;
  position: relative;
  p {
    color: #ffffff;
    font-size: 1.375rem !important;
    z-index: 1;
  }
  button {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
    gap: 4rem;
    grid-column: 2 / 12;
    justify-content: space-between;
    margin: 3rem 0;
    padding: 2.625rem 2rem;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  border-radius: 10px;
`;

export default CallToActionBanner;
