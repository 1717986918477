import * as React from "react";
const SvgBiopharma5 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 58 48"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.602 29.621c-.848-8.573-3.032-17.298 4.729-25.167M20.978 22.062l4.907 4.972M21.28 15.048 31.8 25.71M23.464 9.946l11.278 11.429M20.953 26.921c6.214.566 12.37.222 18.067-5.55"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M27.336 33.265c8.792 0 15.92-7.223 15.92-16.133S36.128 1 27.336 1 11.417 8.223 11.417 17.132c0 8.91 7.127 16.133 15.92 16.133ZM40.724 30.703l-2.127-2.16M44.53 30.7a2.664 2.664 0 0 0-3.804 0 2.75 2.75 0 0 0 0 3.854l10.938 11.084a2.664 2.664 0 0 0 3.803 0 2.75 2.75 0 0 0 0-3.853L44.529 30.7Z"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.584 43.763c5.103-4.57 4.318-9.62 3.649-14.673M3.734 32.1l7.836 7.944M5.927 29.24l7.31 7.404M10.048 28.33l3.293 3.333M1 32.07c4.05-4.104 8.133-4.213 12.606-3.756"
    />
  </svg>
);
export default SvgBiopharma5;
