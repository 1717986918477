import * as React from "react";
const SvgTeam1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 79 83"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.59 49.6s-.926-6.679-1.575-11.208c-.562-3.955-2.711-5.486-5.371-5.893-.882-.134-1.67.597-1.643 1.493l.679 22.54a2.65 2.65 0 0 0 .765 1.778l15.439 15.444"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M30.364 75.091c.813-7.186-.19-11.24-3.338-14.39-2.634-2.634-4.346-3.724-8.1-4.464l-4.818-4.82c-.692-.691-3.07-2.526-6.249-.241-.674.484-.73 1.483-.147 2.067l9.1 9.103M34.183 82v-6.433H12.591V82M43.952 82v-6.433h21.593V82"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M34.183 82v-6.433H12.591V82"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M34.183 82v-6.433H12.591V82M68.55 49.6s.926-6.679 1.575-11.208c.562-3.955 2.711-5.486 5.37-5.893.883-.134 1.67.597 1.644 1.493l-.679 22.54a2.65 2.65 0 0 1-.765 1.778L60.256 73.754"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M47.771 75.091c-.813-7.186.19-11.24 3.339-14.39 2.633-2.634 4.346-3.724 8.1-4.464l4.817-4.82c.692-.691 3.07-2.526 6.25-.241.674.484.73 1.483.146 2.067l-9.099 9.103M43.952 82v-6.433h21.593V82"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M43.952 82v-6.433h21.593V82"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.974 9.752a4.378 4.378 0 0 0 4.372-4.374A4.384 4.384 0 0 0 26.974 1a4.381 4.381 0 0 0-4.372 4.378 4.378 4.378 0 0 0 4.372 4.374ZM32.056 14.446H20.985c-2.318.056-4.2 2.038-4.2 4.421l.891 12.174c.121 1.874 2.496 2.713 4.07 2.942l.207.026 1.112 15.03M52.338 9.752a4.378 4.378 0 0 0 4.372-4.374A4.384 4.384 0 0 0 52.338 1a4.381 4.381 0 0 0-4.372 4.378 4.378 4.378 0 0 0 4.372 4.374ZM55.733 49.039l1.12-15.03c.064-.004.134-.013.207-.026 1.574-.233 3.901-1.068 4.04-2.937l.89-11.967c.005-.1 0-.168 0-.207 0-2.384-2.262-4.37-4.567-4.426h-11.07M29.47 27.978l.89 12.174c.121 1.874 2.496 2.713 4.07 2.942l.207.026 1.285 17.374h6.928s.039-.017.039-.034l1.28-17.34c.065-.004.134-.013.207-.026 1.574-.233 3.901-1.068 4.04-2.937l.89-11.97c.005-.1 0-.17 0-.209 0-2.383-2.257-4.37-4.566-4.425H33.669c-2.318.056-4.2 2.037-4.2 4.421v.004ZM39.39 18.824a4.378 4.378 0 0 0 4.372-4.374 4.384 4.384 0 0 0-4.372-4.378 4.381 4.381 0 0 0-4.372 4.378 4.378 4.378 0 0 0 4.372 4.374Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M39.39 18.824a4.378 4.378 0 0 0 4.372-4.374 4.384 4.384 0 0 0-4.372-4.378 4.381 4.381 0 0 0-4.372 4.378 4.378 4.378 0 0 0 4.372 4.374ZM42.845 60.494s.04-.017.04-.034l1.28-17.34c.064-.004.133-.013.207-.026 1.574-.233 3.9-1.068 4.04-2.937l.89-11.97c.004-.1 0-.17 0-.209 0-2.383-2.258-4.37-4.567-4.425h-11.07c-2.319.056-4.2 2.037-4.2 4.421l.89 12.174c.122 1.873 2.496 2.713 4.07 2.942l.208.026 1.284 17.374"
    />
  </svg>
);
export default SvgTeam1;
