import * as React from "react";
const SvgBiopharma7 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 34 54"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M33 53C33 26.76 1 27.575 1 1"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.812 49.208h21.624M3.943 42.18h20.169M8.392 35.15h9.075M16.362 19.23h9.401M10.014 12.197h20.173M7.278 5.168h21.629"
    />
    <ellipse cx={4.376} cy={5.168} fill={props.color} rx={0.815} ry={0.828} />
    <ellipse cx={7.168} cy={12.197} fill={props.color} rx={0.815} ry={0.828} />
    <ellipse cx={13.104} cy={19.23} fill={props.color} rx={0.815} ry={0.828} />
    <ellipse cx={21.136} cy={35.151} fill={props.color} rx={0.815} ry={0.828} />
    <ellipse cx={27.859} cy={42.179} fill={props.color} rx={0.815} ry={0.828} />
    <ellipse cx={29.869} cy={49.208} fill={props.color} rx={0.815} ry={0.828} />
  </svg>
);
export default SvgBiopharma7;
