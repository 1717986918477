import * as React from "react";
const SvgBiobanking = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.582 34.293a9.291 9.291 0 1 0 0-18.582 9.291 9.291 0 0 0 0 18.582ZM42.806 31.196a6.194 6.194 0 1 0 0-12.388 6.194 6.194 0 0 0 0 12.388Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M31.224 18.034a6.127 6.127 0 0 0 .194-1.549 6.196 6.196 0 0 0-10.291-4.645M7.19 18.937a6.197 6.197 0 0 0 .004 11.485M20.905 38.939a6.196 6.196 0 0 0 9.84-6.968"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.194 13.388A6.194 6.194 0 1 0 7.194 1a6.194 6.194 0 0 0 0 12.388ZM7.194 49.004a6.194 6.194 0 1 0 0-12.388 6.194 6.194 0 0 0 0 12.388ZM36.612 25.002h-7.739M14.275 17.372l-3.545-5.097M14.275 32.632l-3.545 5.093"
    />
    <circle cx={19.582} cy={25.002} r={0.807} fill={props.color} />
    <circle cx={7.194} cy={7.194} r={0.807} fill={props.color} />
    <circle cx={7.194} cy={42.81} r={0.807} fill={props.color} />
    <circle cx={42.806} cy={25.002} r={0.807} fill={props.color} />
  </svg>
);
export default SvgBiobanking;
