import React, { useRef } from "react";
import styled from "styled-components";
import { motion, useScroll, useTransform, cubicBezier } from "framer-motion";
import { Markup } from "interweave";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { scrollToAnchor } from "../../utils";
import { Button } from "../../components";
import {
  Core1,
  Core2,
  Core3,
  Core4,
  Core5,
  Core6,
  GridBackground,
} from "../../assets/svgs";

const Services = ({ data }) => {
  const { title, services } = data.frontmatter;
  const containerRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["end end", "start start"],
  });

  const useParallax = (value, distance) => {
    return useTransform(value, [0, 1], [-distance, distance], {
      ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
    });
  };

  const SVGMapper = {
    core1: Core1,
    core2: Core2,
    core3: Core3,
    core4: Core4,
    core5: Core5,
    core6: Core6,
  };

  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "assets/images/biobanking" } }
          sort: { name: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 90
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `
  );

  const topY = useParallax(scrollYProgress, 25);
  const middleY = useParallax(scrollYProgress, -15);
  const bottomY = useParallax(scrollYProgress, 25);

  return (
    <Container>
      <InnerContainer id="services">
        <SectionTitle>{title}</SectionTitle>
        {services.map((service, index) => (
          <Grid key={index}>
            <div>
              <SectionSubtitle
                id={service.title.replaceAll(" ", "-").toLowerCase()}
              >
                {service.title}
              </SectionSubtitle>
              <p>
                <Markup content={service.description} />
              </p>
              {index <= 1 ? (
                <Button
                  text="Explore Services"
                  type="button"
                  solid="var(--genseq-purple)"
                  id="explore-services"
                  maxWidth="220px"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/services");
                    setTimeout(() => {
                      scrollToAnchor("biopharma-services", {
                        duration: 1200,
                        offset: -120,
                      });
                    }, 1750);
                  }}
                />
              ) : (
                <Button
                  text="Find Out More"
                  type="button"
                  maxWidth="190px"
                  solid="var(--genseq-purple)"
                  id="find-out-more-biobanking"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/services");
                    setTimeout(() => {
                      scrollToAnchor("biobanking-services", {
                        duration: 1200,
                        offset: -120,
                      });
                    }, 1750);
                  }}
                />
              )}
            </div>
            {service.type === "text" && (
              <Text>
                {service.serviceList.map((type) => (
                  <div>
                    <ul>
                      <li>{type.title}</li>
                      <Markup content={type.text} />
                    </ul>
                  </div>
                ))}
              </Text>
            )}
            {service.type === "graphic" && (
              <Graphics>
                {service.serviceList.map((type) => {
                  const SVG = SVGMapper[type.svg];
                  return (
                    <div key={type.id}>
                      <SVGContainer position={type.id}>
                        <SVG />
                      </SVGContainer>
                      <h5>{type.title}</h5>
                    </div>
                  );
                })}
              </Graphics>
            )}
            {service.type === "images" && (
              <Images ref={containerRef}>
                <TopImage style={{ y: topY }}>
                  <GatsbyImage
                    image={getImage(images.edges[0].node.childImageSharp)}
                    alt={images.edges[0].node.name}
                  />
                </TopImage>
                <MiddleImage style={{ y: middleY }}>
                  <GatsbyImage
                    image={getImage(images.edges[1].node.childImageSharp)}
                    alt={images.edges[1].node.name}
                  />
                </MiddleImage>
                <SVGBackground>
                  <GridBackground />
                </SVGBackground>
                <BottomImage style={{ y: bottomY }}>
                  <GatsbyImage
                    image={getImage(images.edges[2].node.childImageSharp)}
                    alt={images.edges[2].node.name}
                  />
                </BottomImage>
              </Images>
            )}
          </Grid>
        ))}
      </InnerContainer>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 1/7;
  padding: 0 1.25rem;
  @media screen and (min-width: 1024px) {
    grid-column: 2/12;
    padding: 0 0 6rem 0;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  button {
    margin-top: 2rem;
  }
  @media screen and (min-width: 1024px) {
    display: grid;
    gap: 8.625rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin: 1.25rem 0;
  ul {
    margin-left: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    ul {
      margin-left: 2rem;
      list-style-type: disc;
    }
  }
  @media screen and (min-width: 1024px) {
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Graphics = styled.div`
  align-items: start;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 3rem 0;
  div {
    display: flex;
    flex-direction: column;
    &:nth-of-type(2) {
      gap: 0.25rem;
    }
  }
  h5 {
    font-size: 1.25rem;
  }
  @media screen and (min-width: 1024px) {
    margin: unset;
  }
`;

const SectionTitle = styled.h3`
  color: var(--genseq-purple);
  font-size: 2.25rem;
  font-weight: 400;
  text-align: left;
  width: 100%;
`;

const SectionSubtitle = styled.h4`
  color: var(--genseq-blue);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

const SVGContainer = styled.div`
  height: auto;
  width: ${({ position }) =>
    position === 6
      ? "48px"
      : position === 5
      ? "38px"
      : position < 3
      ? "48px"
      : "32px"};
  margin-bottom: 0.875rem;
`;

const SVGBackground = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  grid-column: 1/7;
  grid-row-start: 0;
  grid-row-end: 1;
  z-index: 1;
  svg {
    position: absolute;
    height: 100%;
    width: auto;
    right: -6rem;
    top: 40%;
  }
  @media screen and (min-width: 1024px) {
    svg {
      right: -12rem;
      top: 25%;
    }
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  right: -2rem;
  margin-top: 3rem;
  img {
    box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
    height: auto;
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    margin: 0;
    right: -8rem;
  }
`;

const TopImage = styled(motion.div)`
  grid-column: 3 / 7;
  grid-row-start: 0;
  grid-row-end: 1;
  z-index: 2;
  padding-right: 2rem;
  @media screen and (min-width: 1024px) {
    padding: 0;
    grid-column: 3 / 7;
  }
`;

const MiddleImage = styled(motion.div)`
  grid-column: 1 / 5;
  grid-row-start: 0;
  grid-row-end: 1;
  margin-top: auto;
  margin-bottom: -3rem;
  margin-left: -2rem;
  padding-right: 3.125rem;
  z-index: 2;
  @media screen and (min-width: 1024px) {
    grid-column: 1 / 4;
    margin-bottom: -3rem;
    margin-left: -3rem;
    padding-right: 3.125rem;
  }
`;

const BottomImage = styled(motion.div)`
  grid-column: 3 / 7;
  padding: 0.75rem 4rem;
  position: relative;
  right: -1rem;
  z-index: 0;
  @media screen and (min-width: 1024px) {
    right: 0;
    grid-column: 3 / 6;
    padding: 0.75rem 3rem;
  }
`;

export default Services;
