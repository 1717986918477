import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const WhoWeAre = () => {
  const { storyline } = useStaticQuery(
    graphql`
      query {
        storyline: allFile(
          filter: {
            relativePath: { eq: "assets/images/our-team/origins-storyline.png" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
        }
      }
    `
  );
  return (
    <Container>
      <div>
        <Title>Who We Are</Title>
        <p>
          Genseq is a 100% privately owned company based in Cherrywood, Dublin,
          Ireland. The company was formed in September 2023 after acquiring a
          state-of-the-art, purpose-built 1100 sq meters genomics laboratory in
          Dublin. Our laboratory is College of American Pathologists (CAP) and
          IE ISO 15189:2022 accredited, and our focus is on growing our
          portfolio of accredited genetic services to the Biopharma & Clinical
          Research sectors along with healthcare practitioners in the EU and
          worldwide.
          <br />
          <br />
          The Irish owners of the business also own a number of other successful
          healthcare focused businesses based in both the UK and Ireland.
        </p>
      </div>
      <ImageContainer>
        <h4>
          Company Ownership Timeline
          <br />
          <span>Associated Companies</span>
        </h4>
        <GatsbyImage
          image={getImage(storyline.edges[0].node)}
          alt="company ownership timeline"
        />
      </ImageContainer>
    </Container>
  );
};

const Container = styled.section`
  grid-column: 1 / 7;
  padding: 3rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column: 2 / 12;
    padding: 4rem 0;
    gap: 6rem;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const Title = styled.h2`
  color: var(--genseq-purple);
  font-size: 1.875rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 1024px) {
    font-size: 2.25rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--genseq-blue);
  h4 {
    font-size: 1.125rem;
  }
  span {
    font-weight: 300;
    font-size: 1rem;
  }
`;

export default WhoWeAre;
