import * as React from "react";
const SvgLinkedinIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#061C57" />
    <path
      fill="#fff"
      d="M9.337 16.68v-6.119H7.502v6.12h1.835Zm0-8.304c0-.546-.36-.947-.971-.947-.54 0-.936.4-.936.947 0 .546.36.947.936.947.611.036.971-.4.971-.947Zm3.06 8.305v-3.497c0-.182 0-.364.072-.51.144-.364.468-.765 1.044-.765.756 0 1.188.437 1.188 1.275v3.496h1.872v-3.569c0-1.93-1.008-2.805-2.34-2.805-1.116 0-1.584.62-1.872 1.057v-.802h-1.836c.036.583 0 6.156 0 6.156h1.872v-.037Z"
    />
  </svg>
);
export default SvgLinkedinIcon;
