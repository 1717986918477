import * as React from "react";
const SvgBiopharma10 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 48"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M38.023 4.178c-6.223 6.124 2.03 14.241-4.189 20.365"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M46.27 12.292c-6.222 6.124-14.47-1.998-20.693 4.121M44.965 13.36l-5.529-5.44M41.388 14.424l-2.764-2.72M27.212 15.737l5.529 5.44M30.297 14.19l2.764 2.72"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M43.27 24.944a13.428 13.428 0 0 1-7.623 2.352c-7.375 0-13.357-5.887-13.357-13.146C22.29 6.892 28.267 1 35.643 1 43.018 1 49 6.887 49 14.146c0 2.894-.951 5.566-2.557 7.738"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M23.138 9.517a18.914 18.914 0 0 0-3.208-.271C9.475 9.246 1 17.698 1 28.123 1 38.548 9.475 47 19.93 47s18.93-8.452 18.93-18.877c0-.407-.015-.81-.04-1.212"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.93 29.067a.945.945 0 1 0 0-1.891.945.945 0 1 0 0 1.891Z"
    />
  </svg>
);
export default SvgBiopharma10;
