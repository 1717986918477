import * as React from "react";
const SvgArrowBack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 20"
    {...props}
  >
    <path
      stroke="#1AE299"
      strokeWidth={2}
      d="M10.61 1 2 10l8.61 9M2.741 10h19.26"
    />
  </svg>
);
export default SvgArrowBack;
