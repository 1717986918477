import * as React from "react";
const SvgLaboratory4 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 88 70"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M68.176 61.269H87V1H60.38l-8.19 8.312h-43V61.27h13.64"
    />
    <ellipse cx={27.447} cy={61.269} fill="#061C57" rx={1.156} ry={1.173} />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 59.192V1h49.144"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M63.795 41.183H32.61c-.639 0-1.156.525-1.156 1.173v25.47c0 .649.517 1.174 1.156 1.174h31.186c.638 0 1.156-.525 1.156-1.173v-25.47c0-.649-.517-1.174-1.156-1.174ZM60.38 41.183h-6.092v-7.726c0-3.408-2.734-6.183-6.092-6.183-3.358 0-6.092 2.775-6.092 6.183v7.726h-6.091v-7.726c0-6.816 5.461-12.366 12.183-12.366 6.722 0 12.183 5.544 12.183 12.366v7.726Z"
    />
  </svg>
);
export default SvgLaboratory4;
