import * as React from "react";
const SvgFullGrid = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 871 173"
    {...props}
  >
    <g stroke="#E5E5E5" strokeMiterlimit={10} strokeWidth={2} opacity={0.6}>
      <path d="M268.323 171.661h23.834a3.061 3.061 0 0 0 2.635-1.504l13.511-22.815a3.071 3.071 0 0 0 0-3.121l-13.511-22.814a3.066 3.066 0 0 0-2.635-1.505h-23.834c-14.294 0-25.881 11.584-25.881 25.875 0 14.29 11.587 25.874 25.881 25.874v.01ZM449.903 119.555H426.07a3.064 3.064 0 0 0-2.636 1.504l-13.511 22.814a3.073 3.073 0 0 0 0 3.122l13.511 22.814a3.07 3.07 0 0 0 2.636 1.504h23.833c14.294 0 25.881-11.583 25.881-25.874 0-14.29-11.587-25.874-25.881-25.874v-.01ZM348.832 59.676h-23.834a3.064 3.064 0 0 0-2.635 1.504l-13.511 22.815a3.071 3.071 0 0 0 0 3.12l13.511 22.815a3.069 3.069 0 0 0 2.635 1.505h23.834c14.294 0 25.881-11.584 25.881-25.874 0-14.291-11.587-25.875-25.881-25.875v-.01ZM275.565 59.676h-86.491c-2.374 0-3.848 2.584-2.635 4.625l11.658 19.689c.568.962.568 2.159 0 3.12L186.439 106.8c-1.208 2.042.261 4.625 2.635 4.625h86.491c14.294 0 25.881-11.583 25.881-25.874 0-14.29-11.587-25.874-25.881-25.874ZM26.88 53.173h55.785c2.374 0 3.848-2.584 2.635-4.625L73.643 28.86a3.07 3.07 0 0 1 0-3.122L85.301 6.05c1.207-2.041-.262-4.625-2.636-4.625H26.88C12.587 1.425 1 13.009 1 27.299s11.587 25.874 25.88 25.874Z" />
      <path d="m410.306 166.683-11.658-19.689a3.071 3.071 0 0 1 0-3.121l11.658-19.688c1.208-2.042-.261-4.625-2.635-4.625H373.1c-2.375 0-3.849 2.583-2.636 4.625l11.659 19.688a3.071 3.071 0 0 1 0 3.121l-11.659 19.689c-1.208 2.041.261 4.625 2.636 4.625h34.571c2.374 0 3.848-2.584 2.635-4.625ZM208.246 145.787c0 9.742-4.601 18.404-11.751 23.945-.768.594-.343 1.821.624 1.821h12.309c14.232 0 25.773-11.537 25.773-25.766 0-14.229-11.541-25.767-25.773-25.767h-12.309c-.972 0-1.397 1.228-.624 1.821 7.15 5.542 11.751 14.204 11.751 23.946Z" />
      <path d="M173.982 120.02h-71.506c-14.234 0-25.773 11.536-25.773 25.767 0 14.23 11.54 25.766 25.773 25.766h71.506c14.234 0 25.774-11.536 25.774-25.766 0-14.231-11.54-25.767-25.774-25.767ZM281.748 27.294c0-9.742 4.601-18.404 11.75-23.946.768-.593.343-1.821-.624-1.821H173.962c-14.233 0-25.774 11.538-25.774 25.767s11.541 25.766 25.774 25.766h118.912c.972 0 1.397-1.228.624-1.821-7.149-5.541-11.75-14.204-11.75-23.945ZM617.183 145.429c0-9.742 4.601-18.404 11.751-23.945.767-.594.343-1.822-.625-1.822H509.397c-14.232 0-25.773 11.538-25.773 25.767s11.541 25.767 25.773 25.767h118.912c.973 0 1.398-1.228.625-1.822-7.15-5.541-11.751-14.203-11.751-23.945ZM66.267 85.555c0 9.742-4.6 18.404-11.75 23.946-.768.593-.343 1.821.624 1.821h104.613c14.233 0 25.774-11.538 25.774-25.767S173.987 59.79 159.754 59.79H55.146c-.972 0-1.397 1.227-.624 1.821 7.15 5.541 11.75 14.204 11.75 23.945h-.005Z" />
      <path d="M139.012 27.294c0-9.742 4.6-18.404 11.75-23.946.768-.593.343-1.821-.624-1.821H99.369c-1.08 0-2.083.568-2.636 1.499l-13.5 22.702a3.066 3.066 0 0 0 0 3.131l13.5 22.702a3.065 3.065 0 0 0 2.636 1.5h50.769c.972 0 1.397-1.229.624-1.822-7.15-5.541-11.75-14.204-11.75-23.945ZM315.934 145.787c0 9.742-4.601 18.404-11.75 23.945-.768.594-.343 1.821.624 1.821h50.768c1.08 0 2.083-.567 2.636-1.499l13.501-22.702a3.066 3.066 0 0 0 0-3.131l-13.501-22.702a3.066 3.066 0 0 0-2.636-1.499h-50.768c-.972 0-1.397 1.228-.624 1.821 7.149 5.542 11.75 14.204 11.75 23.946ZM843.881 171.308c14.294 0 25.881-11.584 25.881-25.874 0-14.291-11.587-25.874-25.881-25.874h-69.049a3.064 3.064 0 0 0-2.636 1.504l-13.511 22.814a3.071 3.071 0 0 0 0 3.121l13.511 22.815a3.07 3.07 0 0 0 2.636 1.504h69.049v-.01Z" />
      <path d="M650.643 171.308h107.566c2.375 0 3.849-2.584 2.636-4.625l-11.658-19.689a3.071 3.071 0 0 1 0-3.121l11.658-19.688c1.208-2.042-.261-4.625-2.636-4.625H650.643c-14.294 0-25.88 11.583-25.88 25.874 0 14.29 11.586 25.874 25.88 25.874ZM626.974 59.676c-14.294 0-25.88 11.584-25.88 25.874s11.586 25.874 25.88 25.874h90.12a3.064 3.064 0 0 0 2.636-1.504l13.511-22.814a3.071 3.071 0 0 0 0-3.121L719.73 61.17a3.07 3.07 0 0 0-2.636-1.504h-90.12v.01ZM800.641 53.173h23.834a3.064 3.064 0 0 0 2.636-1.504l13.511-22.815a3.071 3.071 0 0 0 0-3.12L827.111 2.918a3.07 3.07 0 0 0-2.636-1.504h-23.834c-14.294 0-25.88 11.583-25.88 25.874 0 14.29 11.586 25.874 25.88 25.874v.01ZM463.885 59.676h-86.491c-2.375 0-3.849 2.584-2.636 4.625l11.659 19.689c.568.962.568 2.159 0 3.12L374.758 106.8c-1.208 2.042.261 4.625 2.636 4.625h86.491c14.294 0 25.88-11.583 25.88-25.874 0-14.29-11.586-25.874-25.88-25.874ZM743.041 1H656.55c-2.375 0-3.849 2.584-2.636 4.625l11.659 19.689c.568.962.568 2.159 0 3.12l-11.659 19.689c-1.207 2.041.261 4.625 2.636 4.625h86.491c14.294 0 25.881-11.583 25.881-25.874C768.922 12.584 757.335 1 743.041 1Z" />
      <path d="M820.36 59.676h-86.491c-2.374 0-3.848 2.584-2.635 4.625l11.658 19.689c.568.962.568 2.159 0 3.12L731.234 106.8c-1.208 2.042.261 4.625 2.635 4.625h86.491c14.294 0 25.881-11.583 25.881-25.874 0-14.29-11.587-25.874-25.881-25.874ZM567.592 59.676h-11.95c-14.234 0-25.773 11.536-25.773 25.767 0 14.23 11.539 25.766 25.773 25.766h11.95c14.235 0 25.774-11.536 25.774-25.766 0-14.23-11.539-25.767-25.774-25.767Z" />
      <path d="M522.847 85.443c0-9.742 4.601-18.404 11.751-23.945.767-.594.342-1.822-.625-1.822h-12.308c-14.233 0-25.773 11.538-25.773 25.767s11.54 25.766 25.773 25.766h12.308c.973 0 1.397-1.227.625-1.821-7.15-5.541-11.751-14.203-11.751-23.945ZM316.098 26.88c0-9.743 4.601-18.405 11.751-23.946.767-.594.342-1.822-.625-1.822h-12.308c-14.233 0-25.773 11.538-25.773 25.767 0 14.23 11.54 25.767 25.773 25.767h12.308c.973 0 1.397-1.228.625-1.822-7.15-5.54-11.751-14.203-11.751-23.945Z" />
      <path d="M351.063 52.64h71.506c14.234 0 25.773-11.535 25.773-25.766 0-14.23-11.539-25.767-25.773-25.767h-71.506c-14.235 0-25.774 11.537-25.774 25.767 0 14.23 11.539 25.767 25.774 25.767Z" />
      <path d="M456.766 26.88c0 9.74-4.601 18.403-11.75 23.944-.768.594-.343 1.822.624 1.822h118.912c14.233 0 25.773-11.538 25.773-25.767s-11.54-25.767-25.773-25.767H445.64c-.972 0-1.397 1.228-.624 1.822 7.149 5.541 11.75 14.203 11.75 23.945Z" />
      <path d="M599.501 26.88c0 9.74-4.601 18.403-11.75 23.944-.768.594-.343 1.822.624 1.822h50.769c1.08 0 2.083-.568 2.636-1.5l13.5-22.701a3.062 3.062 0 0 0 0-3.132l-13.5-22.702a3.065 3.065 0 0 0-2.636-1.499h-50.769c-.972 0-1.397 1.228-.624 1.822 7.149 5.541 11.75 14.203 11.75 23.945Z" />
    </g>
  </svg>
);
export default SvgFullGrid;
