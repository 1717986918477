import * as React from "react";
const SvgListDropdownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path d="M4.00024 10.9998L17.9939 10.8517" stroke={props.open ? "#E5E5E5" : "#1AE299"} />
    {!props.open && <path d="M11.071 18.0708L11.2191 4.07717" stroke="#1AE299" />}
    <circle cx={11} cy={11} r={10.5} stroke={props.open ? "#E5E5E5" : "#1AE299"} />
  </svg>
);
export default SvgListDropdownIcon;
