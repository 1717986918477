import * as React from "react";
const SvgLaboratory5 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 82"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M25.485 81H5.153A4.154 4.154 0 0 1 1 76.847V5.153A4.154 4.154 0 0 1 5.153 1h49.33a4.154 4.154 0 0 1 4.154 4.153v38.44"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.434 9.108v27.706h36.745M18.032 36.813v-5.205M24.265 36.814V25.545M30.504 36.815v-6.996M36.742 36.813V26.44M42.98 36.816V23.227M30.966 81h1.076"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M44.639 11.808 33.479 21.1l-7.55-5.596-7.897 7.723M30.714 64.177c0 5.343-4.334 9.67-9.677 9.67s-9.67-4.333-9.67-9.67h9.67v-9.67c5.343 0 9.677 4.334 9.677 9.67ZM15.725 49.192v9.67h-9.67c0-5.343 4.333-9.67 9.67-9.67Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M38.16 50.117c0 15.44 20.483 15.44 20.483 30.88"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M58.643 50.117c0 15.44-20.483 15.44-20.483 30.88M58.366 53.084H44.639M55.265 58.86H48.4M39.332 78.11h13.721M41.207 72.327h6.864"
    />
  </svg>
);
export default SvgLaboratory5;
