import * as React from "react";
const SvgBiopharma8 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 52 52"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.156 45.052c10.441 0 18.906-8.462 18.906-18.901 0-10.44-8.465-18.901-18.906-18.901-10.441 0-18.906 8.462-18.906 18.9 0 10.44 8.465 18.902 18.906 18.902Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M25.846 34.614c4.846 0 8.775-3.927 8.775-8.771 0-4.844-3.929-8.771-8.775-8.771s-8.775 3.927-8.775 8.771c0 4.844 3.929 8.771 8.775 8.771Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M25.973 27.815a1.86 1.86 0 0 0 1.865-1.854 1.86 1.86 0 0 0-1.865-1.854 1.86 1.86 0 0 0-1.866 1.854 1.86 1.86 0 0 0 1.866 1.854Z"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeDasharray="8 30"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26 51a24.92 24.92 0 0 0 17.678-7.322 25.081 25.081 0 0 0 5.358-7.946A24.867 24.867 0 0 0 51 26c0-1.727-.173-3.41-.507-5.039a24.958 24.958 0 0 0-1.457-4.693 24.622 24.622 0 0 0-2.307-4.245 25.334 25.334 0 0 0-3.05-3.7 25.35 25.35 0 0 0-3.7-3.054A24.92 24.92 0 0 0 26 1 24.92 24.92 0 0 0 8.322 8.322a25.082 25.082 0 0 0-5.357 7.946A24.87 24.87 0 0 0 1 26c0 1.727.173 3.41.507 5.039a24.96 24.96 0 0 0 1.457 4.693 24.63 24.63 0 0 0 2.307 4.245 25.34 25.34 0 0 0 3.05 3.7 25.353 25.353 0 0 0 3.7 3.054A24.92 24.92 0 0 0 26 51Z"
    />
  </svg>
);
export default SvgBiopharma8;
