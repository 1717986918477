import React, { useContext } from "react";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { Button } from "../../../components";
import  { LocalContext } from '../../../context';
import { motion } from "framer-motion";

const CookieBar = () => {
  const location = useLocation();
  const { setShowCookieBar } = useContext(LocalContext);

  const handleAcceptCookies = (event) => {
    event.preventDefault();
    initializeAndTrack(location);
    document.cookie = "gatsby-gdpr-google-analytics=true";
    document.cookie = "genseq-cookie-policy-set=true";
    setShowCookieBar(false);
  };
  const handleDeclineCookies = (event) => {
    event.preventDefault();
    document.cookie = "gatsby-gdpr-google-analytics=false";
    document.cookie = "genseq-cookie-policy-set=true";
    setShowCookieBar(false);
  };
  return (
    <Overlay
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ ease: "easeInOut", duration: 0.375, delay: 0.375 }}
    >
      <Container>
        <p data-nosnippet="">
          When you allow cookies that means you agree to storing information about how you interact with our site, we can then use this for the purpose of enhancing site navigation, analyzing
          site usage, and assisting in our marketing efforts. All your data is anonymised and can't be used to identify you.
        </p>
        <ButtonContainer>
          <Button
            data-nosnippet=""
            onClick={handleDeclineCookies}
            whileHover={{
              scale: 1.0375,
              transition: { ease: "easeInOut", duration: 0.375 },
            }}
            whileTap={{ scale: 0.9 }}
            solid="var(--genseq-purple)"
            text="Decline"
          />
          <Button
            data-nosnippet=""
            onClick={handleAcceptCookies}
            whileHover={{
              scale: 1.0375,
              transition: { ease: "easeInOut", duration: 0.375 },
            }}
            whileTap={{ scale: 0.9 }}
            type="button"
            solid="var(--genseq-blue)"
            text="Allow"
          />
        </ButtonContainer>
      </Container>
    </Overlay>
  );
};

const Overlay = styled(motion.div)`
  align-items: flex-end;
  background: transparent;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 12;
  top: 0;
  left: 0;
  position: fixed;
`;

const Container = styled(motion.div)`
  align-items: center;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
    flex-direction: column;
  padding: 1rem 2rem;
  width: 100%;
  gap: 2rem;
  z-index: 10;
  p {
    color: #061c57;
    font-size: 1rem;
    line-height: 24px;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 1rem 6rem;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export default CookieBar;
