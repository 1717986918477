import * as React from "react";
const SvgBiopharma3 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 54 49"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M45.002 37.908V19.44l-15.994-9.232-15.993 9.232v18.47l15.993 9.235 15.994-9.236ZM45.002 19.469 53 14.85M29.009 1v9.233M13.16 37.895l-9.04 5.22"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29.365 15 17 22.14M8.234 36 1 40.177M41 35.279V21"
    />
  </svg>
);
export default SvgBiopharma3;
