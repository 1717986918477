import * as React from "react";
const SvgMenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 14"
    {...props}
  >
    <path stroke="#fff" strokeWidth={2} d="M0 1h18M3 7h12M0 13h18" />
  </svg>
);
export default SvgMenuIcon;
