import * as React from "react";
const SvgPlayButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 128"
    {...props}
  >
    <circle cx={64} cy={64} r={64} fill="#645FE6" />
    <path
      fill="#fff"
      d="M94.087 62.274c1.318.773 1.318 2.68 0 3.452L47.264 93.154c-1.334.78-3.011-.18-3.011-1.726V36.572c0-1.545 1.678-2.507 3.01-1.726l46.824 27.428Z"
    />
  </svg>
);
export default SvgPlayButton;
