import {
  AortopathyPanel,
  ArrhythmogenicRightVentricularCardiomyopathyPanel,
  BrugadaSyndromeCorePanel,
  BrugadaSyndromeExpandedPanel,
  Cardiology,
  ComprehensiveCardiacArrhythmiasPanel,
  ComprehensiveCardiomyopathyPanel,
  CptvPanel,
  DcmAndConductionDefectsCorePanel,
  DcmAndConductionDefectsExpandedPanel,
  DefaultIcon,
  DyslipidaemiaPanel,
  HcmCorePanel,
  HcmExpandedPanel,
  HereditaryCancerBrca1Brca2,
  HereditaryBreastAndGynaecologicalCancerPanel,
  HereditaryBreastCancerPanel,
  HereditaryCancer,
  HereditaryCancerLynchSyndrome,
  HereditaryColorectalCancerAndPolyposisPanel,
  LqtsCorePanel,
  LqtsExpandedPanel,
  LvncPanel,
  SqtsPanel,
  TransthyretinAmyloidosisTtr,
  SingleGeneTest,
  Bioinformatics
} from "../assets/dual-toned-icons";
import { Biobanking, Biopharma1, Biopharma2, Biopharma3, Biopharma4, Biopharma5, Biopharma6, Biopharma7, Biopharma8, Biopharma9, Biopharma10 } from "../assets/animated-svgs";


const serviceIconMapper = {
  'bioinformatics': Bioinformatics,
  'biobanking-and-sample-management': Biobanking,
  'nucleic-acid-extractions': Biopharma1,
  'genotyping': Biopharma2,
  'methylation-profiling': Biopharma3,
  'whole-genome-sequencing': Biopharma4,
  'whole-exome-sequencing': Biopharma5,
  'sanger-sequencing': Biopharma6,
  'total-rna-sequencing': Biopharma7,
  'single-cell-immune-profiling': Biopharma8,
  'single-cell-gene-expression': Biopharma9,
  'single-nuclei-gene-expression': Biopharma10,
  'cardiology': Cardiology,
  'hereditary-cancer': HereditaryCancer,
  'aortopathy-panel': AortopathyPanel,
  'arrhythmogenic-right-ventricular-cardiomyopathy-panel': ArrhythmogenicRightVentricularCardiomyopathyPanel,
  'brugada-syndrome-core-panel': BrugadaSyndromeCorePanel,
  'brugada-syndrome-expanded-panel': BrugadaSyndromeExpandedPanel,
  'catecholaminergic-polymorphic-ventricular-tachycardia-cpvt-panel': CptvPanel,
  'comprehensive-cardiac-arrhythmias-panel': ComprehensiveCardiacArrhythmiasPanel,
  'comprehensive-cardiomyopathy-panel': ComprehensiveCardiomyopathyPanel,
  'dilated-cardiomyopathy-dcm-and-conduction-defects-core-panel': DcmAndConductionDefectsCorePanel,
  'dilated-cardiomyopathy-dcm-and-conduction-defects-expanded-panel': DcmAndConductionDefectsExpandedPanel,
  'dyslipidaemia-panel-includes-familial-hypercholesterolaemia': DyslipidaemiaPanel,
  'hypertrophic-cardiomyopathy-hcm-core-panel': HcmCorePanel,
  'hypertrophic-cardiomyopathy-hcm-expanded-panel': HcmExpandedPanel,
  'left-ventricular-noncompaction-cardiomyopathy-lvnc-panel': LvncPanel,
  'long-qt-syndrome-lqts-core-panel': LqtsCorePanel,
  'long-qt-syndrome-lqts-expanded-panel': LqtsExpandedPanel,
  'short-qt-syndrome-sqts-panel': SqtsPanel,
  'hereditary-breast-cancer-panel': HereditaryBreastCancerPanel,
  'hereditary-breast-and-gynaecological-cancer-panel': HereditaryBreastAndGynaecologicalCancerPanel,
  'hereditary-colorectal-cancer-and-polyposis-panel': HereditaryColorectalCancerAndPolyposisPanel,
  'hereditary-cancer-brca1-brca2': HereditaryCancerBrca1Brca2,
  'hereditary-cancer-lynch-syndrome-panel': HereditaryCancerLynchSyndrome,
  'transthyretin-amyloidosis-ttr-single-gene-test': TransthyretinAmyloidosisTtr,
  'single-gene-test': SingleGeneTest
};

export default serviceIconMapper;
