import React from "react";
import ReactDOM from 'react-dom/client';
import { Layout } from "./src/components";
import { LocalContextProvider } from "./src/context/LocalContext";

export const replaceHydrateFunction = () => (element, container) => {
  const root = ReactDOM.createRoot(container);
  root.render(element);
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <LocalContextProvider>{element}</LocalContextProvider>
);
