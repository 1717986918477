import * as React from "react";
const SvgBiopharma1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 42"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M42.944 34.334h2.704V41H2.353v-6.666h2.704M28.059 34.334h6.764M13.177 34.334h6.764"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M44.296 2.332V1H33.47v1.332c0 1.474 1.213 2.665 2.704 2.665h5.412c1.496 0 2.704-1.195 2.704-2.665h.005ZM29.415 2.332V1H18.59v1.332c0 1.474 1.213 2.665 2.704 2.665h5.412c1.496 0 2.705-1.195 2.705-2.665h.004ZM14.53 2.332V1H3.703v1.332c0 1.474 1.213 2.665 2.704 2.665h5.413c1.495 0 2.704-1.195 2.704-2.665h.004ZM34.823 14.332v18.666c0 2.664 1.967 4 4.06 4 2.094 0 4.061-1.331 4.061-4.266v-18.4M34.823 19.666h8.121M34.823 8.998V5.001h8.121v3.997M19.941 8.998V5.001h8.117v3.997M5.057 8.998V5.001h8.12v3.997"
    />
    <ellipse cx={38.883} cy={24.999} fill={props.color} rx={0.842} ry={0.83} />
    <ellipse cx={38.883} cy={31.665} fill={props.color} rx={0.842} ry={0.83} />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.941 13v19.997c0 2.665 1.967 4.002 4.06 4.002 2.094 0 4.061-1.333 4.061-4.267v-18.4M19.941 26.332h8.117"
    />
    <ellipse cx={24.003} cy={31.665} fill={props.color} rx={0.842} ry={0.83} />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5.057 14.332v18.666c0 2.664 1.967 4 4.06 4 2.093 0 4.06-1.331 4.06-4.266v-18.4M5.057 18.333h8.12"
    />
    <ellipse cx={9.117} cy={32.998} fill={props.color} rx={0.842} ry={0.83} />
    <ellipse cx={9.117} cy={27.664} fill={props.color} rx={0.842} ry={0.83} />
    <ellipse cx={9.117} cy={22.334} fill={props.color} rx={0.842} ry={0.83} />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.116 8.998h23.002M15.881 14.332H1V8.998h8.117M38.883 8.998H47v5.334H24.002"
    />
  </svg>
);
export default SvgBiopharma1;
