import * as React from "react";
const SvgGridBackground = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 795 236"
    {...props}
  >
    <g stroke="#E5E5E5" strokeMiterlimit={10} strokeWidth={2} opacity={0.6}>
      <path d="M184.617 121.913c-14.294 0-25.881 11.584-25.881 25.874 0 14.291 11.587 25.875 25.881 25.875h90.119a3.063 3.063 0 0 0 2.636-1.505l13.511-22.814a3.071 3.071 0 0 0 0-3.121l-13.511-22.815a3.07 3.07 0 0 0-2.636-1.504h-90.119v.01ZM26.88 61.406C12.588 61.406 1 72.989 1 87.28c0 14.29 11.587 25.874 25.88 25.874h90.125a3.064 3.064 0 0 0 2.636-1.504l13.511-22.815a3.071 3.071 0 0 0 0-3.121L119.641 62.9a3.07 3.07 0 0 0-2.636-1.505H26.881v.01Z" />
      <path d="M175.461 61.406H135.03c-2.375 0-3.849 2.583-2.636 4.625l11.659 19.688c.568.962.568 2.16 0 3.121l-11.659 19.689c-1.207 2.041.261 4.625 2.636 4.625h40.431c14.294 0 25.88-11.584 25.88-25.874s-11.586-25.874-25.88-25.874Z" />
      <path d="M290.908 61.406h-86.491c-2.374 0-3.848 2.583-2.635 4.625l11.658 19.688c.568.962.568 2.16 0 3.121l-11.658 19.689c-1.208 2.041.261 4.625 2.635 4.625h86.491c14.294 0 25.881-11.584 25.881-25.874s-11.587-25.874-25.881-25.874ZM125.23 122.026h-11.95c-14.235 0-25.774 11.536-25.774 25.767 0 14.23 11.54 25.766 25.774 25.766h11.95c14.234 0 25.773-11.536 25.773-25.766 0-14.231-11.539-25.767-25.773-25.767Z" />
      <path d="M80.49 147.793c0-9.742 4.6-18.404 11.75-23.946.768-.593.343-1.821-.624-1.821H79.307c-14.232 0-25.773 11.538-25.773 25.767s11.54 25.766 25.773 25.766h12.309c.972 0 1.397-1.228.624-1.821-7.15-5.541-11.75-14.204-11.75-23.945ZM240.431 1.113h-40.046c-14.235 0-25.774 11.536-25.774 25.766 0 14.23 11.539 25.767 25.774 25.767h40.046c14.235 0 25.774-11.536 25.774-25.767 0-14.23-11.539-25.766-25.774-25.766Z" />
      <path d="M165.486 26.88c0-9.742 4.601-18.405 11.751-23.946.767-.593.343-1.821-.625-1.821h-12.308c-14.233 0-25.773 11.537-25.773 25.766 0 14.23 11.54 25.767 25.773 25.767h12.308c.973 0 1.397-1.228.625-1.822-7.15-5.54-11.751-14.203-11.751-23.945ZM880.423 122.026h-71.506c-14.234 0-25.773 11.536-25.773 25.767 0 14.23 11.539 25.766 25.773 25.766h71.506c14.234 0 25.773-11.536 25.773-25.766 0-14.231-11.539-25.767-25.773-25.767ZM305.474 28.44a3.067 3.067 0 0 1 0-3.121s11.407-18.41 15.082-23.193c.353-.46.02-1.126-.563-1.126h-21.29c-14.514 0-26.669 11.875-26.388 26.38.272 14.06 11.751 25.374 25.876 25.374h21.802c.583 0 .916-.666.563-1.126-3.675-4.779-15.082-23.193-15.082-23.193v.005ZM118.331 182.764c-14.294 0-25.88 11.584-25.88 25.874s11.586 25.874 25.88 25.874h90.119a3.064 3.064 0 0 0 2.636-1.504l13.511-22.815a3.071 3.071 0 0 0 0-3.121l-13.511-22.814a3.07 3.07 0 0 0-2.636-1.504h-90.119v.01Z" />
      <path d="M310.161 182.764H223.67c-2.374 0-3.848 2.584-2.635 4.625l11.658 19.689a3.071 3.071 0 0 1 0 3.121l-11.658 19.688c-1.208 2.041.261 4.625 2.635 4.625h86.491c14.294 0 25.881-11.583 25.881-25.874 0-14.29-11.587-25.874-25.881-25.874ZM740.809 234.518H827.3c2.375 0 3.848-2.584 2.635-4.626l-11.658-19.688a3.071 3.071 0 0 1 0-3.121l11.658-19.689c1.208-2.041-.26-4.625-2.635-4.625h-86.491c-14.294 0-25.881 11.584-25.881 25.874 0 14.291 11.587 25.875 25.881 25.875ZM606.2 234.405h40.047c14.234 0 25.774-11.536 25.774-25.767 0-14.23-11.54-25.767-25.774-25.767H606.2c-14.234 0-25.773 11.537-25.773 25.767 0 14.231 11.539 25.767 25.773 25.767Z" />
      <path d="M681.146 208.638c0 9.742-4.601 18.404-11.751 23.945-.767.594-.342 1.822.625 1.822h12.308c14.233 0 25.773-11.538 25.773-25.767s-11.54-25.767-25.773-25.767H670.02c-.973 0-1.397 1.228-.625 1.822 7.15 5.541 11.751 14.203 11.751 23.945ZM367.732 234.405h10.092c14.234 0 25.773-11.536 25.773-25.767 0-14.23-11.539-25.767-25.773-25.767h-10.092c-14.235 0-25.774 11.537-25.774 25.767 0 14.231 11.539 25.767 25.774 25.767Z" />
      <path d="M412.016 208.638c0 9.742-4.601 18.404-11.75 23.945-.768.594-.343 1.822.624 1.822h149.107c14.233 0 25.773-11.538 25.773-25.767s-11.54-25.767-25.773-25.767H400.89c-.972 0-1.397 1.228-.624 1.822 7.149 5.541 11.75 14.203 11.75 23.945ZM749.816 121.913h-86.491c-2.374 0-3.848 2.584-2.635 4.626l11.658 19.688a3.071 3.071 0 0 1 0 3.121l-11.658 19.688c-1.208 2.042.261 4.626 2.635 4.626h86.491c14.294 0 25.881-11.584 25.881-25.874 0-14.291-11.587-25.875-25.881-25.875ZM570.146 147.793c0 9.741-4.601 18.404-11.751 23.945-.767.593-.342 1.821.625 1.821h12.308c14.233 0 25.773-11.537 25.773-25.766 0-14.229-11.54-25.767-25.773-25.767H559.02c-.973 0-1.397 1.228-.625 1.821 7.15 5.542 11.751 14.204 11.751 23.946Z" />
      <path d="M535.181 122.026h-71.506c-14.234 0-25.773 11.536-25.773 25.767 0 14.23 11.539 25.766 25.773 25.766h71.506c14.234 0 25.773-11.536 25.773-25.766 0-14.231-11.539-25.767-25.773-25.767Z" />
      <path d="M429.478 147.793c0-9.742 4.601-18.404 11.75-23.946.768-.593.343-1.821-.624-1.821H321.692c-14.233 0-25.774 11.538-25.774 25.767s11.541 25.766 25.774 25.766h118.912c.972 0 1.397-1.228.624-1.821-7.149-5.541-11.75-14.204-11.75-23.945ZM606.278 147.793c0 9.741-4.601 18.404-11.751 23.945-.767.593-.343 1.821.625 1.821h50.768c1.08 0 2.083-.568 2.636-1.499l13.501-22.702a3.066 3.066 0 0 0 0-3.131l-13.501-22.702a3.066 3.066 0 0 0-2.636-1.499h-50.768c-.973 0-1.398 1.228-.625 1.821 7.15 5.542 11.751 14.204 11.751 23.946ZM549.838 26.88c0-9.742 4.601-18.405 11.751-23.946.768-.593.343-1.821-.625-1.821h-74.658c-14.233 0-25.773 11.537-25.773 25.766 0 14.23 11.54 25.767 25.773 25.767h74.658c.973 0 1.398-1.228.625-1.822-7.15-5.54-11.751-14.203-11.751-23.945ZM776.532 52.759c14.294 0 25.881-11.584 25.881-25.875 0-14.29-11.587-25.874-25.881-25.874h-69.05a3.061 3.061 0 0 0-2.635 1.505l-13.511 22.814a3.071 3.071 0 0 0 0 3.121l13.511 22.815a3.067 3.067 0 0 0 2.635 1.504h69.05v-.01Z" />
      <path d="M583.299 52.759h107.566c2.374 0 3.848-2.584 2.635-4.626l-11.658-19.688a3.071 3.071 0 0 1 0-3.121L693.5 5.636c1.208-2.042-.261-4.626-2.635-4.626H583.299c-14.294 0-25.881 11.584-25.881 25.874s11.587 25.875 25.881 25.875ZM456.679 60.904h-23.834a3.064 3.064 0 0 0-2.636 1.504l-13.51 22.815a3.067 3.067 0 0 0 0 3.12l13.51 22.815a3.07 3.07 0 0 0 2.636 1.505h23.834c14.294 0 25.881-11.584 25.881-25.874s-11.587-25.875-25.881-25.875v-.01Z" />
      <path d="m417.083 108.032-11.659-19.688a3.071 3.071 0 0 1 0-3.121l11.659-19.689c1.207-2.041-.261-4.625-2.636-4.625h-34.571c-2.374 0-3.848 2.584-2.635 4.625l11.658 19.689c.568.962.568 2.159 0 3.12l-11.658 19.689c-1.208 2.042.261 4.626 2.635 4.626h34.571c2.375 0 3.849-2.584 2.636-4.626ZM616.933 86.778c0-9.742 4.601-18.404 11.75-23.945.768-.594.343-1.822-.624-1.822H516.168c-14.232 0-25.773 11.538-25.773 25.767s11.541 25.767 25.773 25.767h111.891c.972 0 1.397-1.228.624-1.822-7.149-5.541-11.75-14.203-11.75-23.945Z" />
      <path d="M322.71 87.137c0 9.741-4.601 18.404-11.75 23.945-.768.593-.343 1.821.624 1.821h50.769c1.08 0 2.083-.568 2.636-1.499l13.5-22.702a3.067 3.067 0 0 0 0-3.131l-13.5-22.702a3.065 3.065 0 0 0-2.636-1.5h-50.769c-.972 0-1.397 1.229-.624 1.822 7.149 5.541 11.75 14.204 11.75 23.945ZM843.631 112.658c14.294 0 25.881-11.584 25.881-25.875 0-14.29-11.587-25.874-25.881-25.874h-69.049a3.064 3.064 0 0 0-2.636 1.504l-13.511 22.815a3.071 3.071 0 0 0 0 3.121l13.511 22.815a3.07 3.07 0 0 0 2.636 1.504h69.049v-.01Z" />
      <path d="M650.398 112.658h107.566c2.374 0 3.848-2.584 2.635-4.626l-11.658-19.688a3.071 3.071 0 0 1 0-3.121l11.658-19.689c1.208-2.041-.261-4.625-2.635-4.625H650.398c-14.294 0-25.881 11.584-25.881 25.874 0 14.291 11.587 25.875 25.881 25.875ZM439.739 51.254 453.25 28.44a3.071 3.071 0 0 0 0-3.121L439.739 2.504A3.07 3.07 0 0 0 437.103 1H331.078a3.058 3.058 0 0 0-2.636 1.504L314.931 25.32a3.071 3.071 0 0 0 0 3.12l13.511 22.815a3.07 3.07 0 0 0 2.636 1.505h106.025a3.058 3.058 0 0 0 2.636-1.505Z" />
    </g>
  </svg>
);
export default SvgGridBackground;
