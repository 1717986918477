import React from "react";
import styled from "styled-components";
import { Markup } from "interweave";
import { NarrowCarousel } from '../../components';
import { useWindowSize } from "../../hooks";

const Accreditations = () => {
  const { windowWidth } = useWindowSize();

  const accreditationStatements = [
    {title: "High-throughput \nNext-Generation \nSequencing"},
    {title: ">500,000 \nBiobanking Sample \nCapacity"},
    {title: "Highly \nExperienced \nTeam"},
    {title: "Specialists in \nBiopharma and Clinical \nResearch Sectors"},
  ];

  return (
    <Container>
      <Card id="accreditation">
        <h2>Internationally Accredited Genomics Laboratory</h2>
        {windowWidth >= 1024 ? (
          <Grid>
            {accreditationStatements.map((statement) => (
              <p>
                <Markup content={statement.title} />
              </p>
            ))}
          </Grid>
        ) : (
          <NarrowCarousel slides={accreditationStatements} minHeight="100px" duration="3500" />
        )}
      </Card>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  padding: 6rem 0 12rem 0;
  @media screen and (min-width: 1024px) {
    padding: 12rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.375rem;
  text-align: center;
  width: calc(100% - 2.5rem);
  max-width: 1400px;
  transform: translate(-50%, 0);
  padding: 2.25rem 0;
  position: absolute;
  top: -120px;
  left: 50%;
  z-index: 5;
  h2 {
    color: var(--genseq-green);
    font-size: 1.875rem;
    font-weight: 400;
    max-width: 290px;
  }
  p {
    font-size: 1.5rem;
    width: 250px;
  }
  @media screen and (min-width: 1024px) {
    top: -90px;
    width: calc(100% - 9.75rem);
    gap: 5rem;
    padding: 5rem 0;
    h2 {
      font-size: 3rem;
      max-width: 560px;
    }
    p {
      font-size: 1.5rem;
    }
  }
`;

const Grid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.75rem;
  justify-content: center;
`;

export default Accreditations;
