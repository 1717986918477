import * as React from "react";
const SvgTeam3 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 83 78"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M61.115 69.872v5.203c0 1.062-.883 1.925-1.971 1.925H2.972C1.884 77 1 76.137 1 75.075V2.925C1 1.863 1.884 1 2.972 1h56.176c1.088 0 1.972.863 1.972 1.925V14.12M61.116 65.775v.945"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.906 24.3V11.407h13.207M18.509 20.932l4.858 4.708 10.578-11.243M11.906 46.79V33.895h13.207M18.509 43.42l4.858 4.71 10.578-11.244M11.906 69.275V56.38h13.207"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M80.512 45.845c1.374-10.457-6.194-20.022-16.904-21.364-10.71-1.341-20.507 6.049-21.88 16.506-1.375 10.457 6.194 20.022 16.904 21.364 10.71 1.341 20.506-6.049 21.88-16.506Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m68.705 39.176-7.584 7.405-10.303-10.059M65.614 18.42h-8.992M65.614 18.42h-8.992"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M80.512 45.845c1.374-10.457-6.194-20.022-16.904-21.364-10.71-1.341-20.507 6.049-21.88 16.506-1.375 10.457 6.194 20.022 16.904 21.364 10.71 1.341 20.506-6.049 21.88-16.506Z"
    />
  </svg>
);
export default SvgTeam3;
