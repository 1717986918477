import * as React from "react";
const SvgTeam2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 86 86"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M42.997 44.354V43M42.997 76.87V49.776M22.95 71.179l5.144-5.145v-8.13l5.42-5.42M19.965 78.224a4.062 4.062 0 1 0 0-8.123 4.062 4.062 0 0 0 0 8.123ZM42.997 85a4.062 4.062 0 1 0 0-8.124 4.062 4.062 0 0 0 0 8.123ZM5.062 59.252a4.062 4.062 0 1 0 0-8.123 4.062 4.062 0 0 0 0 8.123ZM63.045 71.179l-5.144-5.145v-8.13l-5.421-5.42"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M66.03 78.224a4.062 4.062 0 1 0 0-8.123 4.062 4.062 0 0 0 0 8.123ZM80.933 59.252a4.062 4.062 0 1 0 0-8.123 4.062 4.062 0 0 0 0 8.123ZM19.965 48.421V55.191H9.13"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M66.03 43c0-12.722-10.312-23.034-23.033-23.034-12.72 0-23.032 10.312-23.032 23.034"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M76.866 55.19H66.029v-6.769"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 43c0-2.78.276-5.499.796-8.13h5.542a36.361 36.361 0 0 1 4.697-11.34l-.497-.497-3.426-3.426a42.256 42.256 0 0 1 11.5-11.5l3.426 3.426.497.498a36.226 36.226 0 0 1 11.34-4.698V1.796C37.504 1.282 40.222 1 43.002 1c2.78 0 5.498.276 8.128.796v5.543a36.355 36.355 0 0 1 11.34 4.697l.497-.497 3.426-3.427a42.159 42.159 0 0 1 11.494 11.5l-3.426 3.427-.497.497a36.232 36.232 0 0 1 4.697 11.34h5.542c.514 2.63.796 5.35.796 8.13"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M33.515 43a9.48 9.48 0 0 1 9.482-9.483 9.48 9.48 0 0 1 9.483 9.482"
    />
  </svg>
);
export default SvgTeam2;
