import * as React from "react";
const SvgBioinformatics = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 42"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.402 31.306H49M14.198 41h21.6M21.4 34.94 19 41M28.6 34.94 31 41M26.201 34.94H3.398C1.814 34.94 1 34.117 1 32.517V3.423C1 1.823 1.814 1 3.398 1h43.2c1.591 0 2.398.819 2.398 2.423v29.094c0 1.6-.814 2.423-2.398 2.423h-15.6M5.8 31.306H3.398"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.875 20.69h-4.8v7.272h4.8v-7.271ZM18.931 15.717h-4.856v2.264h4.856v-2.264ZM35.742 8.547h-4.856v2.264h4.856V8.547ZM27.467 17.057h-4.8v10.91h4.8v-10.91ZM27.524 11.189h-4.857v3.396h4.856v-3.396ZM27.524 4.774h-4.857V8.17h4.856V4.774ZM18.931 7.415h-4.856v5.66h4.856v-5.66Z"
    />
    <ellipse cx={35.798} cy={23.117} fill={props.color} rx={0.747} ry={0.755} />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M35.798 25.54v2.426h-4.797V13.419h4.797v7.272"
    />
  </svg>
);
export default SvgBioinformatics;
