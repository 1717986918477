import React from "react";
import { motion } from "framer-motion";

const SvgHeaderPattern = (props) => {
  const container = {
    misaligned: { opacity: 0 },
    aligned: {
      opacity: 1,
      transition: {
        delay: 1,
        delayChildren: 1.25,
        ease: "linear",
      },
    },
  };

  const item = {
    misaligned: {
      x: -50,
    },
    aligned: {
      x: 0,
      transition: {
        ease: "linear",
        duration: 0.25
      }
    },
  };

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1650 751"
      variants={container}
      initial="misaligned"
      whileInView="aligned"
      viewport={{ once: true }}
      style={{ overflow: 'visible' }}
      {...props}
    >
      <g stroke="#fff" strokeMiterlimit={10} strokeWidth={2} opacity={0.3}>
        <path
          d="M1601.25 221.226h-130.12c-25.9 0-46.9 20.992-46.9 46.886 0 25.895 21 46.887 46.9 46.887h130.12c25.9 0 46.9-20.992 46.9-46.887 0-25.894-21-46.886-46.9-46.886ZM1134.57 701.413c0-17.726 8.37-33.489 21.38-43.572 1.4-1.08.63-3.314-1.13-3.314H938.439c-25.898 0-46.898 20.994-46.898 46.886 0 25.892 21 46.887 46.898 46.887h216.381c1.77 0 2.54-2.234 1.13-3.314-13.01-10.083-21.38-25.846-21.38-43.573ZM1152.39 545.373c-26.01 0-47.1 21.078-47.1 47.082s21.09 47.082 47.1 47.082h163.98a5.58 5.58 0 0 0 4.8-2.737l24.59-41.515a5.605 5.605 0 0 0 0-5.679l-24.59-41.515a5.592 5.592 0 0 0-4.8-2.737h-163.98v.019ZM1579.7 331.748h-43.37a5.592 5.592 0 0 0-4.8 2.737L1506.95 376a5.558 5.558 0 0 0 0 5.679l24.58 41.515a5.592 5.592 0 0 0 4.8 2.737h43.37c26.01 0 47.09-21.078 47.09-47.082s-21.08-47.082-47.09-47.082v-.019ZM855.622 545.373H698.238c-4.321 0-7.003 4.702-4.796 8.416l21.214 35.826a5.588 5.588 0 0 1 0 5.68l-21.214 35.826c-2.198 3.714.475 8.416 4.796 8.416h157.384c26.01 0 47.094-21.078 47.094-47.082s-21.084-47.082-47.094-47.082Z"
          opacity={0.4}
        />
        <path
          d="M1504.28 545.373H1346.9c-4.32 0-7 4.702-4.79 8.416l21.21 35.826a5.607 5.607 0 0 1 0 5.68l-21.21 35.826c-2.2 3.714.47 8.416 4.79 8.416h157.38c26.02 0 47.1-21.078 47.1-47.082s-21.08-47.082-47.1-47.082ZM1044.33 545.373h-21.74c-25.903 0-46.9 20.992-46.9 46.886 0 25.895 20.997 46.887 46.9 46.887h21.74c25.9 0 46.9-20.992 46.9-46.887 0-25.894-21-46.886-46.9-46.886Z"
          opacity={0.4}
        />
        <path
          d="M962.913 592.259c0-17.726 8.372-33.489 21.382-43.572 1.397-1.08.624-3.314-1.136-3.314h-22.397c-25.899 0-46.899 20.995-46.899 46.886 0 25.892 21 46.887 46.899 46.887h22.397c1.769 0 2.542-2.234 1.136-3.314-13.01-10.083-21.382-25.846-21.382-43.573ZM1347.2 425.922h157.38c4.32 0 7.01-4.702 4.8-8.417l-21.22-35.826a5.605 5.605 0 0 1 0-5.679l21.22-35.826c2.2-3.715-.48-8.416-4.8-8.416H1347.2c-26.01 0-47.09 21.078-47.09 47.082 0 26.003 21.08 47.082 47.09 47.082ZM1102.26 425.717h72.87c25.9 0 46.9-20.992 46.9-46.887 0-25.894-21-46.886-46.9-46.886h-72.87c-25.9 0-46.9 20.992-46.9 46.886 0 25.895 21 46.887 46.9 46.887Z"
          opacity={0.4}
        />
        <path
          d="M1238.63 378.83c0 17.727-8.37 33.489-21.38 43.573-1.4 1.08-.62 3.314 1.14 3.314h22.39c25.9 0 46.9-20.995 46.9-46.887s-21-46.886-46.9-46.886h-22.39c-1.77 0-2.55 2.234-1.14 3.314 13.01 10.083 21.38 25.846 21.38 43.572ZM1363.59 221.021h-157.38c-4.33 0-7.01 4.702-4.8 8.416l21.21 35.826a5.56 5.56 0 0 1 0 5.68l-21.21 35.826c-2.2 3.714.47 8.416 4.8 8.416h157.38c26.01 0 47.09-21.078 47.09-47.082s-21.08-47.082-47.09-47.082ZM1036.65 268.112c0 17.727-8.37 33.489-21.38 43.572-1.4 1.08-.63 3.315 1.13 3.315h22.4c25.9 0 46.9-20.995 46.9-46.887s-21-46.886-46.9-46.886h-22.4c-1.76 0-2.54 2.234-1.13 3.314 13.01 10.083 21.38 25.846 21.38 43.572Z"
          opacity={0.4}
        />
        <path
          d="M973.026 221.226H842.91c-25.901 0-46.898 20.992-46.898 46.886 0 25.895 20.997 46.887 46.898 46.887h130.116c25.902 0 46.894-20.992 46.894-46.887 0-25.894-20.992-46.886-46.894-46.886ZM1102.4 268.112c0 17.727-8.37 33.489-21.38 43.572-1.4 1.08-.63 3.315 1.13 3.315h92.38c1.97 0 3.79-1.033 4.8-2.728l24.57-41.31a5.593 5.593 0 0 0 0-5.698l-24.57-41.309a5.581 5.581 0 0 0-4.8-2.728h-92.38c-1.77 0-2.54 2.234-1.13 3.314 13.01 10.083 21.38 25.846 21.38 43.572ZM1519.38 95.183h43.37c1.97 0 3.79-1.043 4.8-2.737l24.58-41.515a5.605 5.605 0 0 0 0-5.68l-24.58-41.514a5.603 5.603 0 0 0-4.8-2.737h-43.37c-26.01 0-47.09 21.079-47.09 47.082 0 26.004 21.08 47.082 47.09 47.082v.019Z"
          opacity={0.4}
        />
        <path d="M1412.2 95.183c26.01 0 47.1-21.079 47.1-47.082 0-26.004-21.09-47.082-47.1-47.082h-125.64a5.579 5.579 0 0 0-4.8 2.737l-24.59 41.514a5.605 5.605 0 0 0 0 5.68l24.59 41.514a5.591 5.591 0 0 0 4.8 2.737h125.64v-.018Z" />
        <path d="M1060.58 95.183h195.74c4.32 0 7-4.702 4.79-8.417L1239.9 50.94a5.605 5.605 0 0 1 0-5.679l21.21-35.826c2.2-3.715-.47-8.416-4.79-8.416h-195.74c-26.01 0-47.09 21.078-47.09 47.082 0 26.003 21.08 47.082 47.09 47.082ZM1547.09 748.505c26.01 0 47.09-21.079 47.09-47.082 0-26.004-21.08-47.082-47.09-47.082h-125.65a5.59 5.59 0 0 0-4.8 2.737l-24.58 41.514a5.56 5.56 0 0 0 0 5.68l24.58 41.514a5.59 5.59 0 0 0 4.8 2.738h125.65v-.019Z" />
        <path d="M1195.46 748.505h195.73c4.32 0 7.01-4.702 4.8-8.417l-21.22-35.826a5.605 5.605 0 0 1 0-5.679l21.22-35.826c2.2-3.715-.48-8.416-4.8-8.416h-195.73c-26.01 0-47.1 21.078-47.1 47.082 0 26.003 21.09 47.082 47.1 47.082Z" />
        <motion.path
          d="M499.767 749.147h43.369a5.573 5.573 0 0 0 4.796-2.737l24.585-41.515a5.586 5.586 0 0 0 0-5.679l-24.585-41.514a5.585 5.585 0 0 0-4.796-2.738h-43.369c-26.01 0-47.094 21.079-47.094 47.082 0 26.004 21.084 47.083 47.094 47.083v.018ZM830.18 654.331h-43.369a5.573 5.573 0 0 0-4.796 2.737l-24.586 41.515a5.59 5.59 0 0 0 0 5.679l24.586 41.515a5.585 5.585 0 0 0 4.796 2.737h43.369c26.01 0 47.094-21.078 47.094-47.082S856.19 654.35 830.18 654.35v-.019Z"
          opacity={0.4}
          variants={item}
        />
        <motion.path
          d="m758.128 740.088-21.215-35.826a5.59 5.59 0 0 1 0-5.679l21.215-35.826c2.197-3.715-.475-8.416-4.796-8.416h-62.907c-4.321 0-7.003 4.701-4.796 8.416l21.214 35.826a5.586 5.586 0 0 1 0 5.679l-21.214 35.826c-2.198 3.715.475 8.417 4.796 8.417h62.907c4.321 0 7.003-4.702 4.796-8.417ZM390.446 702.065c0 17.727-8.372 33.489-21.382 43.572-1.397 1.08-.624 3.315 1.136 3.315h22.397c25.898 0 46.898-20.995 46.898-46.887s-21-46.887-46.898-46.887H370.2c-1.769 0-2.542 2.235-1.136 3.315 13.01 10.083 21.382 25.845 21.382 43.572Z"
          opacity={0.4}
          variants={item}
        />
        <motion.path
          d="M328.097 655.178H197.981c-25.901 0-46.898 20.992-46.898 46.887s20.997 46.887 46.898 46.887h130.116c25.902 0 46.899-20.992 46.899-46.887s-20.997-46.887-46.899-46.887ZM586.402 702.065c0 17.727-8.372 33.489-21.381 43.572-1.397 1.08-.624 3.315 1.136 3.315h92.381a5.578 5.578 0 0 0 4.796-2.728l24.567-41.31a5.58 5.58 0 0 0 0-5.698l-24.567-41.31a5.578 5.578 0 0 0-4.796-2.728h-92.381c-1.77 0-2.543 2.235-1.136 3.315 13.009 10.083 21.381 25.845 21.381 43.572ZM646.264 545.373h-43.369a5.575 5.575 0 0 0-4.796 2.737l-24.585 41.515a5.586 5.586 0 0 0 0 5.679l24.585 41.515a5.587 5.587 0 0 0 4.796 2.737h43.369c26.01 0 47.094-21.079 47.094-47.082 0-26.004-21.084-47.082-47.094-47.082v-.019ZM512.944 545.373H355.56c-4.321 0-7.003 4.702-4.796 8.416l21.215 35.826a5.593 5.593 0 0 1 0 5.68l-21.215 35.826c-2.197 3.714.475 8.416 4.796 8.416h157.384c26.01 0 47.094-21.078 47.094-47.082s-21.084-47.082-47.094-47.082ZM132.094 592.464c0 17.727-8.372 33.489-21.382 43.573-1.397 1.08-.624 3.314 1.136 3.314h190.36c25.899 0 46.899-20.995 46.899-46.887s-21-46.886-46.899-46.886h-190.35c-1.77 0-2.543 2.234-1.136 3.314 13.009 10.083 21.381 25.846 21.381 43.572h-.009ZM60.424 533.539h101.508c4.321 0 7.003-4.701 4.796-8.416l-21.214-35.826a5.586 5.586 0 0 1 0-5.679l21.214-35.826c2.198-3.715-.475-8.417-4.796-8.417H60.424c-26.01 0-47.094 21.079-47.094 47.082 0 26.004 21.084 47.082 47.094 47.082ZM524.194 486.448c0-17.727 8.372-33.489 21.382-43.572 1.397-1.08.624-3.315-1.136-3.315H328.06c-25.898 0-46.898 20.995-46.898 46.887s21 46.887 46.898 46.887h216.38c1.769 0 2.542-2.235 1.136-3.315-13.01-10.083-21.382-25.845-21.382-43.572Z"
          opacity={0.4}
          variants={item}
        />
        <motion.path
          d="M264.464 486.448c0-17.727 8.372-33.489 21.382-43.572 1.397-1.08.624-3.315-1.136-3.315h-92.382a5.578 5.578 0 0 0-4.796 2.728l-24.566 41.31a5.58 5.58 0 0 0 0 5.698l24.566 41.31a5.578 5.578 0 0 0 4.796 2.728h92.382c1.769 0 2.542-2.235 1.136-3.315-13.01-10.083-21.382-25.845-21.382-43.572ZM1468.4 533.539h43.37c1.98 0 3.79-1.042 4.8-2.737l24.58-41.514a5.56 5.56 0 0 0 0-5.68l-24.58-41.514a5.59 5.59 0 0 0-4.8-2.737h-43.37c-26.01 0-47.09 21.078-47.09 47.082 0 26.003 21.08 47.082 47.09 47.082v.018ZM1363.59 438.602h-157.38c-4.33 0-7.01 4.702-4.8 8.417l21.21 35.826a5.558 5.558 0 0 1 0 5.679l-21.21 35.826c-2.2 3.715.47 8.417 4.8 8.417h157.38c26.01 0 47.09-21.079 47.09-47.082 0-26.004-21.08-47.083-47.09-47.083ZM586.7 485.694c0-17.727 8.372-33.489 21.382-43.572 1.397-1.08.624-3.315-1.136-3.315h-22.397c-25.898 0-46.898 20.995-46.898 46.887s21 46.887 46.898 46.887h22.397c1.769 0 2.542-2.235 1.136-3.315-13.01-10.083-21.382-25.845-21.382-43.572Z"
          opacity={0.4}
          variants={item}
        />
        <path
          d="M650.324 532.571h130.117c25.901 0 46.898-20.992 46.898-46.886 0-25.895-20.997-46.887-46.898-46.887H650.324c-25.901 0-46.898 20.992-46.898 46.887 0 25.894 20.997 46.886 46.898 46.886Z"
          opacity={0.4}
        />
        <path
          d="M842.668 485.694c0 17.727-8.372 33.489-21.382 43.572-1.397 1.08-.624 3.315 1.136 3.315H1038.8c25.9 0 46.9-20.995 46.9-46.887s-21-46.887-46.9-46.887H822.422c-1.769 0-2.542 2.235-1.136 3.315 13.01 10.083 21.382 25.845 21.382 43.572Z"
          opacity={0.4}
        />
        <path
          d="M1102.4 485.694c0 17.727-8.37 33.489-21.38 43.572-1.4 1.08-.63 3.315 1.13 3.315h92.38c1.97 0 3.79-1.034 4.8-2.728l24.57-41.31a5.593 5.593 0 0 0 0-5.698l-24.57-41.31a5.583 5.583 0 0 0-4.8-2.728h-92.38c-1.77 0-2.54 2.235-1.13 3.315 13.01 10.083 21.38 25.845 21.38 43.572ZM214.502 331.748c-26.01 0-47.094 21.079-47.094 47.082 0 26.004 21.084 47.082 47.094 47.082h163.986a5.573 5.573 0 0 0 4.796-2.737l24.586-41.514a5.593 5.593 0 0 0 0-5.68l-24.586-41.514a5.584 5.584 0 0 0-4.796-2.737H214.502v.018Z"
          opacity={0.4}
        />
        <path
          d="M563.568 331.748H406.184c-4.321 0-7.003 4.702-4.796 8.417l21.214 35.826a5.586 5.586 0 0 1 0 5.679l-21.214 35.826c-2.198 3.715.475 8.416 4.796 8.416h157.384c26.01 0 47.094-21.078 47.094-47.082 0-26.003-21.084-47.082-47.094-47.082ZM668.326 425.717h18.364c25.902 0 46.899-20.992 46.899-46.887 0-25.894-20.997-46.886-46.899-46.886h-18.364c-25.902 0-46.899 20.992-46.899 46.886 0 25.895 20.997 46.887 46.899 46.887Z"
          opacity={0.4}
        />
        <motion.path
          d="M748.908 378.83c0 17.727-8.372 33.489-21.382 43.573-1.397 1.08-.624 3.314 1.136 3.314h271.324c25.894 0 46.894-20.995 46.894-46.887s-21-46.886-46.894-46.886H728.662c-1.769 0-2.542 2.234-1.136 3.314 13.01 10.083 21.382 25.846 21.382 43.572ZM335.119 221.021c-26.01 0-47.094 21.078-47.094 47.082s21.084 47.082 47.094 47.082h163.987a5.575 5.575 0 0 0 4.796-2.737l24.585-41.515a5.586 5.586 0 0 0 0-5.679l-24.585-41.515a5.587 5.587 0 0 0-4.796-2.737H335.119v.019ZM227.055 221.226H205.31c-25.901 0-46.898 20.992-46.898 46.886 0 25.895 20.997 46.887 46.898 46.887h21.745c25.902 0 46.899-20.992 46.899-46.887 0-25.894-20.997-46.886-46.899-46.886Z"
          opacity={0.4}
          variants={item}
        />
        <motion.path
          d="M145.644 268.112c0-17.726 8.372-33.489 21.382-43.572 1.397-1.08.624-3.314-1.136-3.314h-22.397c-25.899 0-46.899 20.994-46.899 46.886 0 25.892 21 46.887 46.899 46.887h22.397c1.769 0 2.542-2.235 1.136-3.315-13.01-10.083-21.382-25.845-21.382-43.572ZM780.683 268.112c0-17.726 8.372-33.489 21.382-43.572 1.397-1.08.624-3.314-1.136-3.314h-216.38c-25.898 0-46.898 20.994-46.898 46.886 0 25.892 21 46.887 46.898 46.887h216.38c1.769 0 2.542-2.235 1.136-3.315-13.01-10.083-21.382-25.845-21.382-43.572ZM48.094 110.917C22.084 110.917 1 131.996 1 157.999c0 26.004 21.084 47.083 47.094 47.083H212.09a5.574 5.574 0 0 0 4.796-2.738l24.585-41.514a5.586 5.586 0 0 0 0-5.679l-24.585-41.515a5.584 5.584 0 0 0-4.796-2.737H48.094v.018Z"
          opacity={0.4}
          variants={item}
        />
        <motion.path
          d="M318.459 110.917h-73.57c-4.321 0-7.003 4.702-4.796 8.417l21.214 35.826a5.586 5.586 0 0 1 0 5.679l-21.214 35.826c-2.198 3.715.475 8.417 4.796 8.417h73.57c26.01 0 47.094-21.079 47.094-47.083 0-26.003-21.084-47.082-47.094-47.082Z"
          opacity={0.4}
          variants={item}
        />
        <path
          d="M528.534 110.917H371.15c-4.321 0-7.003 4.702-4.796 8.417l21.214 35.826a5.586 5.586 0 0 1 0 5.679l-21.214 35.826c-2.198 3.715.475 8.417 4.796 8.417h157.384c26.01 0 47.094-21.079 47.094-47.083 0-26.003-21.084-47.082-47.094-47.082ZM830.18 110.005h-43.369a5.573 5.573 0 0 0-4.796 2.737l-24.586 41.515a5.59 5.59 0 0 0 0 5.679l24.586 41.515a5.585 5.585 0 0 0 4.796 2.737h43.369c26.01 0 47.094-21.079 47.094-47.082 0-26.004-21.084-47.082-47.094-47.082v-.019Z"
          opacity={0.4}
          variants={item}
        />
        <path
          d="m758.128 195.762-21.215-35.826a5.59 5.59 0 0 1 0-5.679l21.215-35.826c2.197-3.715-.475-8.417-4.796-8.417h-62.907c-4.321 0-7.003 4.702-4.796 8.417l21.214 35.826a5.586 5.586 0 0 1 0 5.679l-21.214 35.826c-2.198 3.715.475 8.417 4.796 8.417h62.907c4.321 0 7.003-4.702 4.796-8.417ZM1121.79 157.087c0-17.727 8.37-33.489 21.38-43.572 1.4-1.08.62-3.315-1.14-3.315h-203.6c-25.899 0-46.899 20.995-46.899 46.887s21 46.887 46.899 46.887h203.6c1.77 0 2.54-2.235 1.14-3.315-13.01-10.083-21.38-25.845-21.38-43.572Z"
          opacity={0.4}
          variants={item}
        />
        <path
          d="M586.402 157.739c0 17.727-8.372 33.489-21.381 43.572-1.397 1.08-.624 3.314 1.136 3.314h92.381a5.577 5.577 0 0 0 4.796-2.727l24.567-41.31a5.58 5.58 0 0 0 0-5.698l-24.567-41.31a5.578 5.578 0 0 0-4.796-2.728h-92.381c-1.77 0-2.543 2.235-1.136 3.315 13.009 10.083 21.381 25.845 21.381 43.572Z"
          opacity={0.4}
          variants={item}
        />
        <g opacity={0.4}>
          <path d="M1534.3 204.179c26.01 0 47.09-21.079 47.09-47.083 0-26.003-21.08-47.082-47.09-47.082h-125.65a5.563 5.563 0 0 0-4.79 2.737l-24.59 41.515a5.605 5.605 0 0 0 0 5.679l24.59 41.515a5.575 5.575 0 0 0 4.79 2.737h125.65v-.018Z" />
          <path d="M1182.68 204.179h195.74c4.32 0 7-4.702 4.79-8.417L1362 159.936a5.558 5.558 0 0 1 0-5.679l21.21-35.826c2.2-3.715-.47-8.417-4.79-8.417h-195.74c-26.01 0-47.09 21.079-47.09 47.082 0 26.004 21.08 47.083 47.09 47.083Z" />
        </g>
        <motion.path
          d="M436.683 1.205h-72.872c-25.901 0-46.898 20.992-46.898 46.887 0 25.894 20.997 46.886 46.898 46.886h72.872c25.901 0 46.898-20.992 46.898-46.886 0-25.895-20.997-46.887-46.898-46.887Z"
          opacity={0.4}
          variants={item}
        />
        <motion.path
          d="M300.309 48.092c0-17.727 8.372-33.49 21.381-43.573 1.397-1.08.624-3.314-1.136-3.314h-22.397c-25.898 0-46.898 20.995-46.898 46.887 0 25.891 21 46.886 46.898 46.886h22.397c1.77 0 2.543-2.234 1.136-3.314-13.009-10.083-21.381-25.846-21.381-43.572ZM555.037 50.931a5.591 5.591 0 0 1 0-5.68s20.758-33.498 27.445-42.203c.642-.838.037-2.048-1.025-2.048h-38.74c-26.411 0-48.528 21.61-48.016 48.004.493 25.585 21.382 46.17 47.085 46.17h39.671c1.062 0 1.667-1.21 1.025-2.049-6.687-8.695-27.445-42.203-27.445-42.203v.01ZM999.698 48.092c0-17.727 8.372-33.49 21.382-43.573 1.4-1.08.62-3.314-1.14-3.314H884.091c-25.899 0-46.899 20.995-46.899 46.887 0 25.891 21 46.886 46.899 46.886h135.849c1.77 0 2.55-2.234 1.14-3.314-13.01-10.083-21.382-25.846-21.382-43.572Z"
          opacity={0.4}
          variants={item}
        />
        <path
          d="M799.355 92.446 823.94 50.93a5.587 5.587 0 0 0 0-5.68L799.355 3.738A5.586 5.586 0 0 0 794.559 1h-192.93a5.561 5.561 0 0 0-4.796 2.737l-24.586 41.515a5.587 5.587 0 0 0 0 5.68l24.586 41.514a5.584 5.584 0 0 0 4.796 2.737h192.93a5.563 5.563 0 0 0 4.796-2.737Z"
          opacity={0.4}
        />
      </g>
    </motion.svg>
  );
};
export default SvgHeaderPattern;
