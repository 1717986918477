import React, { useRef } from "react";
import styled from "styled-components";
import { motion, useScroll, useTransform, cubicBezier } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useWindowSize } from "../../../hooks";
import { BannerGridRight } from "../../../assets/svgs";

const LaboratoryAndTechnology = ({ slides }) => {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: {
            relativeDirectory: { eq: "assets/images/our-team/laboratory" }
          }
          sort: { name: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 90
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `
  );
  const { windowWidth } = useWindowSize();
  const containerRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["end end", "start start"],
  });

  const useParallax = (value, distance) => {
    return useTransform(value, [0, 1], [-distance, distance], {
      ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
    });
  };

  const topY = useParallax(scrollYProgress, 25);
  const bottomY = useParallax(scrollYProgress, -15);
  return (
      <Introduction id="laboratory-and-technology">
        <div>
          <h3>Our Laboratory and Our Technology</h3>
          <p>
            Situated in Dublin, Ireland, the Genseq laboratory spans over 11,000
            sq feet. The laboratory operates under a unidirectional workflow,
            maximising efficiency while minimising contamination risks. Adhering
            to high regulatory standards, we maintain precise monitoring of
            temperature, humidity, and pressure.
          </p>
        </div>
        <Images ref={containerRef}>
          <TopImage style={{ y: topY }}>
            <GatsbyImage
              image={getImage(images.edges[0].node.childImageSharp)}
              alt={images.edges[0].node.name}
            />
          </TopImage>
          <SVGContainerRight>
            <BannerGridRight />
          </SVGContainerRight>
          <BottomImage style={{ y: bottomY }}>
            <GatsbyImage
              image={getImage(images.edges[1].node.childImageSharp)}
              alt={images.edges[1].node.name}
            />
            <GatsbyImage
              image={getImage(images.edges[2].node.childImageSharp)}
              alt={images.edges[2].node.name}
            />
          </BottomImage>
        </Images>
      </Introduction>
  );
};

const Introduction = styled.section`
  grid-column: 1 / 7;
  padding: 3rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  h3,
  p {
    text-align: center;
  }
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column: 2 / 13;
    padding: 4rem 0;
    gap: 6rem;
    h3,
    p {
      text-align: left;
    }
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 13;
  }
`;

const Carousel = styled.section`
  grid-column: 1 / 7;
  padding: 0 1.25rem;
  @media screen and (min-width: 1024px) {
    grid-column: 2 / 12;
    padding: unset;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
`;

const TopImage = styled(motion.div)`
  grid-column: 1 / 5;
  z-index: 1;
  div {
    box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
    bord-radius: 0.25rem;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 1 / 5;
  }
`;

const BottomImage = styled(motion.div)`
  grid-column: 4 / 7;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  position: relative;
  z-index: 3;
  top: -3rem;
  div {
    border-radius: 0.25rem;
    &:nth-of-type(1) {
      grid-column: 1 / 5;
      z-index: 3;
    }
    &:nth-of-type(2) {
      box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
      grid-column: 5 / 7;
      margin-left: 0.75rem;
      z-index: 3;
    }
  }
  @media screen and (min-width: 1024px) {
    top: -8rem;
  }
`;

const SVGContainerRight = styled.div`
  right: -1.25rem;
  top: 0.5rem;
  position: absolute;
  display: flex;
  z-index: 2;
  width: 100%;
  svg {
    width: 75%;
    height: auto;
    margin-left: auto;
  }
  @media screen and (min-width: 1024px) {
    top: -4rem;
    right: 0;
    svg {
      width: 60%;
    }
  }
`;

export default LaboratoryAndTechnology;
