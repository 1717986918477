import React from "react";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";

const ServiceDetails = ({
  title,
  slug,
  included,
  technicalSpecifications,
  deliverables,
  turnaroundTime,
  sampleRequirements,
}) => {
  return (
    <Container>
      <h5>What is included?</h5>
      <Text>
        <BlockContent blocks={included} />
      </Text>
      {technicalSpecifications && (
        <>
          <h5>Technical Specifications</h5>
          <Text>
            <BlockContent blocks={technicalSpecifications} />
          </Text>
        </>
      )}
      <h5>Deliverables</h5>
      <Text>
        <BlockContent blocks={deliverables} />
      </Text>
      {turnaroundTime && (
        <>
          <h5>Turnaround Time</h5>
          <Text>
            <BlockContent blocks={turnaroundTime} />
          </Text>
        </>
      )}
      <h5>Sample Requirements</h5>
      <Text>
        <p>
          For detailed information about the sample requirements for our {title}{" "}
          service, please consult our{" "}
          <a
            href={`/services/biopharma-and-research/resources/sample-requirements/#${slug}/`}
          >
            sample requirements page.
          </a>
        </p>
        {sampleRequirements && <BlockContent blocks={sampleRequirements} />}
      </Text>
      {turnaroundTime && (
        <SmallText>
          *Turnaround times are indicative and may be subject to sample type and
          volume.
        </SmallText>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  position: relative;
  margin-bottom: 2rem;
  h5 {
    font-size: 1rem;
    padding: 1rem 1.25rem;
    &:not(:last-of-type) {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  @media screen and (min-width: 1024px) {
    margin-left: -1.25rem;
    margin-bottom: 0;
  }
`;

const Text = styled.div`
  border-left: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  span {
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
  }
  p {
    margin-bottom: 1.25rem;
  }
  a {
    color: var(--genseq-green);
    position: relative;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid #e5e5e5;
  }
`;

const SmallText = styled.span`
  position: absolute;
  left: 0;
  bottom: -2.25rem;
  font-size: 0.75rem;
  @media screen and (min-width: 1024px) {
    left: 1.25rem;
    bottom: -1.5rem;
  }
`;

export default ServiceDetails;
