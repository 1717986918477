import * as React from "react";
const SvgBiopharma9 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 50"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14.955 29.91 9.533-5.39M5.051 11.794l2.835 5.186 15.9 29.086c1.768 3.23 4.959 3.54 7.415 2.15 2.456-1.39 3.883-4.31 1.941-7.864l-9.575-17.51M16.978 10.784l-2.393-4.38"
    />
    <ellipse cx={23.258} cy={33.678} fill={props.invert ? '#FFFFFF' : '#061C57'} rx={0.911} ry={0.927} />
    <ellipse cx={27.674} cy={41.756} fill={props.invert ? '#FFFFFF' : '#061C57'} rx={0.911} ry={0.927} />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.594 2.617 13.71 1 1 8.188l.884 1.618c.975 1.784 3.19 2.428 4.945 1.436l6.357-3.591c1.755-.992 2.388-3.245 1.413-5.029l-.005-.005Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M30 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M30 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
  </svg>
);
export default SvgBiopharma9;
