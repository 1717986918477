import React from "react";
import styled from "styled-components";
import { NarrowCarousel, LargeCarousel } from "../../components";
import { useWindowSize } from "../../hooks";

const AboutUs = ({ data }) => {
  const { teamAndApproach, laboratoryAndTechnology, title } = data.frontmatter;
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      <InnerContainer>
        <SectionTitle id="about-us">{title}</SectionTitle>
        <Card id="team-and-approach">
          <SectionSubtitle>Our Team and Our Approach</SectionSubtitle>
          {windowWidth >= 1024 ? (
            <LargeCarousel slides={teamAndApproach} minHeight="195px" />
          ) : (
            <NarrowCarousel slides={teamAndApproach} minHeight="100px" duration="8000" />
          )}
        </Card>
        <Card id="laboratory-and-technology">
          <SectionSubtitle>Our Laboratory and Our Technology</SectionSubtitle>
          {windowWidth >= 1024 ? (
            <LargeCarousel slides={laboratoryAndTechnology} minHeight="240px" unequal />
          ) : (
            <NarrowCarousel
              slides={laboratoryAndTechnology}
              minHeight="180px"
              duration="8000"
            />
          )}
        </Card>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 1/7;
  padding: 0 1.25rem;
  @media screen and (min-width: 1024px) {
    grid-column: 2/12;
    padding: 4rem 0 0 0;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const SectionTitle = styled.h3`
  color: var(--genseq-purple);
  font-size: 2.25rem;
  font-weight: 400;
  @media screen and (min-width: 1024px) {
    width: 100%;
  }
`;

const SectionSubtitle = styled.h4`
  color: var(--genseq-blue);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: center;
  @media screen and (min-width: 1024px) {
   text-align: left;
   margin-bottom: 4.25rem;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.625rem 0 3rem 0;
  span {
    font-size: 16px;
    color: var(--genseq-blue);
    text-align: center;
    margin-top: 1rem;
  }
  p {
    text-align: center;
    span {
      color: var(--genseq-blue);
      font-size: 1.25rem;
      font-weight: 700;
    }
  }
  @media screen and (min-width: 820px) {
    margin: 1.875rem 0 6rem 0;
  }
`;

export default AboutUs;
