import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BaseSelect from "react-select";
import { motion } from "framer-motion";
import { useWindowSize } from "../../../../../hooks";
import {
  Label,
  Input,
  FormCheck,
  FormCheckbox,
  Back,
  Message
} from "../../FormComponents";
import { Button } from "../../../../../components";
import { ArrowBack } from "../../../../../assets/svgs";
import FixRequiredSelect from "../../FixRequiredSelect";
import customDropdownMenuStyles from "../../../../../styles/customDropdownMenuStyles";

const FormSelect = (props) => {
  return (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options}
    />
  );
};

const BillingInformation = ({
  formValues,
  setFormValues,
  handleInputChange,
  showFields,
  billingAddress,
  setBillingAddress,
  setFormPage,
}) => {
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);

  const includesSurname = (name) => /\s/.test(name.trim());

  const { windowWidth } = useWindowSize();

  const taxableStatuses = [
    { label: "Registered for VAT", value: "Registered for VAT" },
    { label: "Not Registered for VAT", value: "Not Registered for VAT" },
    { label: "VAT Exempt", value: "VAT Exempt" },
  ];

  const handleAddressInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;

    setBillingAddress((currentValues) => ({
      ...currentValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    setMessage("");
    if (
      billingAddress.address1.length > 1 &&
      billingAddress.county.length > 1 &&
      billingAddress.postcode.length > 1 &&
      billingAddress.country.length > 1 &&
      formValues.financeContact.length > 1 &&
      formValues.financeContactEmail.includes("@")
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    billingAddress,
    formValues.financeContact,
    formValues.financeContactEmail,
  ]);

  const handleNextClick = (e) => {
    e.preventDefault();
    if (!includesSurname(formValues.financeContact)) {
      setMessage("You must provide a full contact name");
      return;
    }
    setFormPage(4);
  };

  return (
    <Fields
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Back
        type="button"
        onClick={() => {
          setFormPage(2);
        }}
      >
        <ArrowBack />
      </Back>
      <p>Billing Address</p>
      {showFields && (
        <small style={{ paddingTop: "1rem" }}>
          Fields marked with an <span>*</span> are mandatory
        </small>
      )}
      {showFields ? (
        <motion.div
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            duration: 1,
            ease: [0.04, 0.62, 0.23, 0.98],
            opacity: { duration: 0.5 },
          }}
        >
          <Label>
            <FormCheck style={{ marginLeft: 0 }}>
              <FormCheckbox
                type="checkbox"
                id="sameAsShipping"
                name="sameAsShipping"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                checked={formValues.sameAsShipping}
                value={formValues.sameAsShipping}
              />
              <p>Use shipping address as billing address</p>
            </FormCheck>
          </Label>
          <Label>
            <p>
              Address 1<span>*</span>
            </p>
            <Input
              type="text"
              id="address1"
              name="address1"
              value={billingAddress.address1}
              onChange={(e) => {
                handleAddressInputChange(e);
              }}
              required
            />
          </Label>
          <Label>
            <p>Address 2</p>
            <Input
              type="text"
              id="address2"
              name="address2"
              value={billingAddress.address2}
              onChange={(e) => {
                handleAddressInputChange(e);
              }}
            />
          </Label>
          <Label>
            <p>Address 3</p>
            <Input
              type="text"
              id="address3"
              name="address3"
              value={billingAddress.address3}
              onChange={(e) => {
                handleAddressInputChange(e);
              }}
            />
          </Label>
          <Label>
            <p>
              County<span>*</span>
            </p>
            <Input
              type="text"
              id="county"
              name="county"
              value={billingAddress.county}
              onChange={(e) => {
                handleAddressInputChange(e);
              }}
              required
            />
          </Label>
          <Label>
            <p>
              Postcode / Eircode<span>*</span>
            </p>
            <Input
              type="text"
              id="postcode"
              name="postcode"
              value={billingAddress.postcode}
              onChange={(e) => {
                handleAddressInputChange(e);
              }}
              required
            />
          </Label>
          <Label>
            <p>
              Country<span>*</span>
            </p>
            <Input
              type="text"
              id="country"
              name="country"
              value={billingAddress.country}
              onChange={(e) => {
                handleAddressInputChange(e);
              }}
              required
            />
          </Label>
        </motion.div>
      ) : (
        <>
          <AddressPrint
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 1,
              ease: [0.04, 0.62, 0.23, 0.98],
              opacity: { duration: 0.5 },
            }}
          >
            {billingAddress.address1}
            <br />
            {billingAddress.address2 && (
              <>
                {billingAddress.address2}
                <br />
              </>
            )}
            {billingAddress.address3 && (
              <>
                {billingAddress.address3}
                <br />
              </>
            )}
            {billingAddress.county}
            <br />
            {billingAddress.postcode}
            <br />
            {billingAddress.country}
          </AddressPrint>
          <Label style={{ padding: 0 }}>
            <FormCheck style={{ marginLeft: 0 }}>
              <FormCheckbox
                type="checkbox"
                id="sameAsShipping"
                name="sameAsShipping"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                checked={formValues.sameAsShipping}
                value={formValues.sameAsShipping}
              />
              <p>Use shipping address as billing address</p>
            </FormCheck>
          </Label>
        </>
      )}
      <p style={{ marginTop: "1.25rem" }}>Billing Details</p>
      <small style={{ marginTop: "0.5rem" }}>
        Fields marked with an <span>*</span> are mandatory
      </small>
      <Label>
        <p>
          Finance Contact<span>*</span>
        </p>
        <Input
          type="text"
          name="financeContact"
          id="financeContact"
          value={formValues.financeContact}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>
          Finance Contact Email<span>*</span>
        </p>
        <Input
          type="email"
          name="financeContactEmail"
          id="financeContactEmail"
          value={formValues.financeContactEmail}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>
          Finance Contact Phone Number<span></span>
        </p>
        <Input
          type="tel"
          name="financeContactPhoneNumber"
          id="financeContactPhoneNumber"
          value={formValues.financeContactPhoneNumber}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </Label>
      <Label>
        <FormCheck>
          <p>Does your institution require a PO to process payments?</p>
          <FormCheckbox
            type="checkbox"
            id="requirePoForPayments"
            name="requirePoForPayments"
            onChange={(e) => {
              handleInputChange(e);
            }}
            checked={formValues.requirePoForPayments}
            value={formValues.requirePoForPayments}
          />
        </FormCheck>
      </Label>
      <Label>
        <p>
          Taxable Status
          <br />
          <span>
            <small>(Customers based in Ireland only)</small>
          </span>
        </p>
        <FormSelect
          value={taxableStatuses.filter(
            ({ value }) => value === formValues.taxableStatus
          )}
          onChange={({ value }) => {
            console.log(value);
            setFormValues((currentValues) => ({
              ...currentValues,
              taxableStatus: value,
            }));
          }}
          id="taxableStatus"
          name="taxableStatus"
          placeholder="Choose a taxable status"
          styles={customDropdownMenuStyles({ windowWidth })}
          options={taxableStatuses}
        />
      </Label>
      <Label>
        <p>
          VAT/Tax ID Number<span></span>
        </p>
        <Input
          type="text"
          name="vatTaxIdNumber"
          id="vatTaxIdNumber"
          value={formValues.vatTaxIdNumber}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </Label>
      {message.length > 1 && (
        <motion.div
          style={{ margin: "1rem 0 0.5rem 0" }}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            duration: 1,
            ease: [0.04, 0.62, 0.23, 0.98],
            opacity: { duration: 0.5 },
          }}
        >
          <Message>{message}</Message>
        </motion.div>
      )}
      <Button
        type="button"
        solid="var(--genseq-blue)"
        maxWidth="260px"
        text="Review Information"
        disabled={disabled}
        onClick={handleNextClick}
      />
    </Fields>
  );
};

const Fields = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const AddressPrint = styled(motion.p)`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 1.25rem 0;
`;

export default BillingInformation;
