import * as React from "react";
const SvgLaboratory1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 88 76"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M57.472 19.845 59 18.292 76.016 1.006 87 12.164 38.561 61.371 9.428 31.783l10.99-11.158 18.143 18.431 14.466-14.701"
    />
    <ellipse cx={55.282} cy={22.069} fill={props.color} rx={1.18} ry={1.199} />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M73.655 34.217c.124 1.24.19 2.506.19 3.783 0 20.434-16.309 37-36.423 37S1 58.434 1 38 17.308 1 37.422 1c8.399 0 16.13 2.884 22.287 7.735"
    />
  </svg>
);
export default SvgLaboratory1;
